import { Container } from "react-bootstrap"
import RichText from "../../../ui/RichText/RichText"
import SpacingMenu from "../../../ui/spacing/SpacingMenu"
import { BasicPageModel } from "./model/BasicPageModel"
import React from "react";

export interface BasicPagePropsType {
    data: BasicPageModel
}

export const BasicPageView: React.FC<BasicPagePropsType> = ({data: {title, content}}) => {

    return <SpacingMenu>
        <Container className="basic-page">
            <h1>{title}</h1>
            {content !== undefined && <RichText className="padding-top-1" content={content} />}
        </Container>
    </SpacingMenu>
}