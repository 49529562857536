import {
    ApiImageData,
} from "../../../data-access/api-call/wagtail-rest-page-api/image/models/ImageModel";
import * as yup from "yup"
import {requiredMessage} from "../../../models/error/ModelErrorValidation";
import {ObjectSchema} from "yup";
import {GetPagesItemData} from "../../../data-access/api-call/wagtail-rest-page-api/page";

export interface ServiceActorLinkItemModelData {
    id: string;
    type: "item",
    value: {
        url: string;
        label: string;
    }
}

export interface ServiceActorLinkModelData {
    item: Array<ServiceActorLinkItemModelData>;
    actor: number;
}

export interface ServiceActorModelData {
    id: string;
    type: "actor",
    value: ServiceActorLinkModelData
}

export interface ThemeServiceModelData {
    id: number,
    locale: string;
    theme: {
        name: string;
        order: number;
    }
}

export interface ServicePageModelData extends ServiceModelData, GetPagesItemData {}

export interface ServiceModelData {
    title: string;
    description: string;
    image?: ApiImageData;
    link: string;
    locale: string;
    theme_pages_infos: Array<ThemeServiceModelData>
}

export const servicePageThemeActorModelDataSchema = yup.object({
    id: yup.string().required(requiredMessage),
    value: yup.object({
        item: yup.array(yup.object({
            value: yup.object({
                url: yup.string().required(requiredMessage),
                label: yup.string().required(requiredMessage)
            })
        })).nullable(),
        actor: yup.string().required(requiredMessage)
    }).required(requiredMessage).required(requiredMessage)
})

export const serviceActorModelDataSchema = yup.object({
    id: yup.string().required(requiredMessage),
    value: yup.object({
        item: yup.array(yup.object({
            url: yup.string().required(requiredMessage),
            label: yup.string().required(requiredMessage)
        })).nullable(),
        actor: yup.string().required(requiredMessage)
    }).required(requiredMessage).required(requiredMessage)
})


// TO DO rename validation schema name
// @ts-ignore
export const serviceModelDataSchema: ObjectSchema<any> = yup.object({
    id: yup.number().nullable(),
    meta: yup.object({
        type: yup.string(),
        detail_url: yup.string(),
    }).nullable(),
    title: yup.string().required(requiredMessage),
    description: yup.string().required(requiredMessage),
    link: yup.string().required(),
    theme_pages_infos: yup.array(yup.object({
        id: yup.number().required(requiredMessage),
        locale: yup.string().required(requiredMessage),
        theme: yup.object({
            name: yup.string().required(requiredMessage),
            order: yup.number().required(requiredMessage)
        }).required(requiredMessage)
    })).required(requiredMessage)
})

// @ts-ignore
export const serviceModelDataPreviewSchema: ObjectSchema<any> = yup.object({
    id: yup.number().nullable(),
    meta: yup.object({
        type: yup.string(),
        detail_url: yup.string(),
    }).nullable(),
    title: yup.string().required(requiredMessage),
    description: yup.string().required(requiredMessage),
    actor: yup.array(serviceActorModelDataSchema).required(requiredMessage),
})