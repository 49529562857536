import React, {useMemo} from "react";
import {UseFormRegisterReturn} from "react-hook-form";
import Select from "../../../ui/form/select/Select";
import {useTranslation} from "react-i18next";
import {useGetThemes} from "../../ThemeData";
import ComponentSwitch from "../../../ui/component-switch/ComponentSwitch";
import {Spinner} from "react-bootstrap";

export interface CategoryMenuProps{
    placeholder?: string;
    register: UseFormRegisterReturn
}

const ThemeMenu: React.FC<CategoryMenuProps> = ({placeholder,register}) => {

    const {t} = useTranslation()

    const {data, isLoading, error} = useGetThemes()

    const themeOptions = useMemo(() => {
        return data && !isLoading
            ? data.map(({id, name}) => ({value: id, label: name}))
            : []
    }, [data, isLoading])

    return <ComponentSwitch loading={isLoading} loadingComponent={<Spinner animation="grow"/>} error={error} >
        {themeOptions.length > 0 && <Select emptyOption labelEmptyOption={t("theme.select.labelEmptyOption")} options={themeOptions} placeholder={placeholder ? placeholder : "Category"} register={register}/>}
    </ComponentSwitch>
}

export default ThemeMenu;