import {ErrorApplicationData} from "../../../models/error/ErrorModel";
import {useTranslation} from "react-i18next";
import React from "react";

export interface ErrorApiProblemProps {
    error?: ErrorApplicationData;
}
const ErrorApiProblem: React.FC<ErrorApiProblemProps> = ({error}) => {
    const {t} = useTranslation()
    return <div className="ErrorUi">
        <i className="bi bi-exclamation-diamond text-warning">
            {` ${t("error.messageApiProblem")} ${error?.model}`}
        </i>
    </div>
}

export default ErrorApiProblem;