import {useParams} from "react-router-dom";
import React, {useMemo} from "react";
import {useGetPage} from "./PageData";
import PageLoading from "./PageLoading";
import ComponentSwitch from "../ui/component-switch/ComponentSwitch";
import {usePageDispatcher} from "./page-dispatching/PageDispatcher";
import {pageDispatchingPreview, pageDispatchingPublic} from "./page-dispatching/PageDispatching";
import {usePreview} from "../../preview/PreviewService";

/**
 * Description - Core Component to manage page by page model
 *  - implement service to fetch page data from wagtail api rest
 *  - implement service to get page view by page model
 *  - manage error state from page data fetching, data validation, data convertion
 *  - render error component
 *  - render loading component
 *  - render page
 * @constructor
 */
const Page: React.FC = () => {

    const {isPreview} = usePreview()
    const params = useParams()
    const pageId = useMemo(() => params.pageId ? params.pageId : undefined, [params])
    const pageDispatchingList = useMemo(() => !isPreview ? pageDispatchingPublic : pageDispatchingPreview, [isPreview])

    const {data, isLoading, error: getPageError} = useGetPage(pageId)
    const {component, error: componentError} = usePageDispatcher({data, pageDispatchingList})

    const error = useMemo(() => {
        if(getPageError){
            console.error(getPageError)
            return getPageError
        }
        if(componentError){
            console.error(componentError)
            return componentError
        }
        return undefined
    }, [getPageError, componentError])

    return <ComponentSwitch
        error={error}
        loading={isLoading}
        loadingComponent={<PageLoading/>}
        isPage
    >
        {component ? component : null}
    </ComponentSwitch>
}

export default Page