import React from 'react';
import Content from "./components/content/Content";
import Navigation from "./components/navigation/Navigation";
import Footer from "./components/footer/Footer";
import FedTopBar from "./components/fed-top-bar/FedTopBar";
import {Router} from "./routing";
import {SiteProvider} from "./data-access/api-call/wagtail-rest-page-api/site/GetSiteId";
import {SeoProvider} from "./seo";

function App() {
  return (
      <div className="App">
          <SiteProvider>
              <SeoProvider>
                  <FedTopBar/>
                  <Router>
                      <div className="body-container">
                          <div className="navigation-container">
                              <Navigation/>
                          </div>
                          <div className="content-container">
                              <Content/>
                          </div>
                          <div className="footer-container">
                              <Footer/>
                          </div>
                      </div>
                  </Router>
              </SeoProvider>
          </SiteProvider>
      </div>
  );
}

export default App;