import React from "react";
import {useGetMenu} from "./MainMenuData";
import {MainMenuModelView} from "./models/MainMenuModelView";
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import ComponentSwitch from "../../ui/component-switch/ComponentSwitch";
import {MAIN_MENU_HTML_COMPONENT_ID} from "../Navigation";
import MainMenuLoading from "./MainMenuLoading";
import {useGetNavigationService} from "../navigation-service/NavigationService";

export interface MenuViewProps {
    data: MainMenuModelView
}

/**
 * Description - View Component to display main navigation menu
 * @param data
 * @constructor
 */
export const MenuView: React.FC<MenuViewProps> = ({data}) => {

    const {pageSelected} = useGetNavigationService()

    return (
        <Navbar.Collapse id={MAIN_MENU_HTML_COMPONENT_ID}>
            <Nav className="ms-auto MainMenu">
                {data.map((navItem) => navItem.children
                    ? <NavDropdown align="end" className="Navigation-item"  title={navItem.label} id={navItem.label} key={navItem.label}>
                        {navItem.children.map(navItemChildren => (
                            navItemChildren.link !== undefined && <NavDropdown.Item className="Navigation-item" key={navItemChildren.label} as={Link} to={navItemChildren.link}>{navItemChildren.label}</NavDropdown.Item>
                        ))}
                    </NavDropdown>
                    : navItem.link !== undefined && <div key={navItem.label}><Nav.Link className={`Navigation-item${ pageSelected === parseInt(navItem.link) ? " nav-link-active" : ""}`} as={Link} to={navItem.link}>{navItem.label}</Nav.Link></div>
                )}
            </Nav>
        </Navbar.Collapse>
    )
}

/**
 * Description - UI Component to render navigation main menu
 *  - implement service to get main menu from wagtail rest api
 *  - rendering in loading state
 *  - rendering main menu view component
 * @constructor
 */
const MainMenu: React.FC = () => {
    const {data, isLoading, error} = useGetMenu()

    return (
        <ComponentSwitch loadingComponent={<MainMenuLoading/>} loading={isLoading} error={error}>
            {data !== undefined && <MenuView data={data}/>}
        </ComponentSwitch>
    )
}

export default MainMenu