import {GetPagesItemData} from "../../data-access/api-call/wagtail-rest-page-api/page";
import {useApiQuery} from "../../data-access/data-caching";
import {useContext, useEffect, useState} from "react";
import ApiCallContext from "../../data-access/api-call/ApiCallContext";
import {TranslationContext} from "../../translation/TranslationProvider";
import {useNavigate} from "react-router-dom";
import {ErrorApplicationData} from "../../models/error/ErrorModel";
import {useGetNavigationService} from "../navigation/navigation-service/NavigationService";
import {usePreview} from "../../preview/PreviewService";
import {useSeoService} from "../../seo";

export const GET_PAGE_QUERY_NAME = "get_page"
export const PAGE_MODEL_NAME = "Page"

/**
 * Description - Service to manage page data from wagtail rest api
 *  - implement service to fetch page data from wagtail rest api
 *  - manage page redirection with translation (not for preview)
 *  - implement navigation service to inject page selected
 *  - manage translation change by page local for preview
 * @param pageId
 */
export const useGetPage = (pageId?: string) => {
    const {setMeta} = useSeoService()
    const {isPreview} = usePreview()
    const {getPage, getTranslationId} = useContext(ApiCallContext)
    const {setPageSelected} = useGetNavigationService()
    const {language, onChangeLanguage} = useContext(TranslationContext)
    const {data, isLoading, error} = useApiQuery<GetPagesItemData>(GET_PAGE_QUERY_NAME, getPage, {
        variables: pageId,
        model: PAGE_MODEL_NAME,
        skip: pageId !== undefined
    })
    const [errorProcess, setErrorProcess] = useState<ErrorApplicationData | undefined>()

    useEffect(() => {
        if(data){
            setPageSelected(data.id)
        }
    },[data, setPageSelected])

    useEffect(() => {
        if(data){
            setMeta({title: data.meta.seo_title, description: data.meta.search_description})
        }
    },[data, setMeta])

    useEffect(() => {
        if(error){
            setErrorProcess(error)
        }
    },[error])

    const navigate = useNavigate()

    // change language with same language (locale) of the current page
    useEffect(() => {
        if(isPreview && data){
            if(data.meta.locale) {
                onChangeLanguage(data.meta.locale)
            }
            else {
                // locale is required en mode preview to switch application in correct language
                console.error("locale property is required in mode preview")
            }
        }

    }, [onChangeLanguage, pageId, data, isPreview])

    // redirection if current page has not current language as locale value (mode normal)
    useEffect(() => {
        if(!isPreview && pageId && data && data.meta.locale !== language){
            getTranslationId(pageId, language).then(translationId => {
                navigate(`/${translationId}`)
            }).catch((errorTranslation) => {
                // problem to fix
                console.error(errorTranslation)
                /*setErrorProcess({
                    type: "run",
                    message: errorTranslation
                })*/
            })
        }
    },[pageId, data, language, getTranslationId, navigate, isPreview])

    return {data, isLoading, error: errorProcess}
}