import {ApiImageData, GetImageData} from "../image/models/ImageModel";
import {ImageAttribute} from "../../../../models/general_model";
import {mapper, Mapping} from "../../../../utils/mapper/mapper";
import {ApiObjectData} from "../models/ApiCallModel";

/**
 * Description - Method to convert object from api from value
 * @param apiObjectData
 * @param mapping
 */
export const convertApiObjectDataToValue = <TValue extends Object = Object, TKey = string, TResult extends Object = Object>(apiObjectData: ApiObjectData<TValue, TKey>, mapping?: Mapping): TResult => {
    // @ts-ignore
    return mapping
        ? mapper<TResult, TValue>(apiObjectData.value, mapping)
        : apiObjectData.value
}
export const convertApiImageDataToImageAttributs = (image: ApiImageData | GetImageData): ImageAttribute => {
    if (process.env.REACT_APP_API_DOMAIN) {
        return mapper<ImageAttribute, ApiImageData>(
            image,
            {
                "altText": "title",
                "imageUrl": {
                    key: "meta",
                    serializer: (value: any): any => `${process.env.REACT_APP_API_DOMAIN}${value.download_url}`
                }
            })
    } else {
        throw Error("the API_DOMAIN variable is missing")
    }
}