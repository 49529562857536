import {ErrorApplicationData} from "../../../models/error/ErrorModel";
import {useTranslation} from "react-i18next";
import React from "react";

export interface ErrorRunningProps {
    error?: ErrorApplicationData;
}
const ErrorRunning: React.FC<ErrorRunningProps> = ({error}) => {
    const {t} = useTranslation()
    return <div className="ErrorUi">
        <i className="bi bi-cone text-warning">
            {` ${t("error.messageRunning")} ${error?.model}`}
        </i>
    </div>
}

export default ErrorRunning;