import {NEWS_INDEX_PAGE_WAGTAIL_MODEL_NAME} from "../../../../news/NewsIndexPageData";
import {PageDispatchingModel} from "../../../page-dispatching/models/PageDispatchingModel";
import NewsPage from "../NewsPage";
import {NewsModelData, newsValidationSchema} from "../../../../news/models/NewsModelData";
import {NewsModelApiCustomView} from "../../../../news/models/NewsModelView";
import {
    convertNewsModelDataToNewsModelViewForCustomNewsApiForPreview,
    NEWS_PAGE_WAGTAIL_MODEL_NAME
} from "../../../../news/NewsData";
import NewsTemplatePreview from "../../../../preview/news-template-preview/NewsTemplatePreview";

export const defaultNewsIndexPageDispatcher: PageDispatchingModel = {
    wagtail_model_name: NEWS_INDEX_PAGE_WAGTAIL_MODEL_NAME,
    serializer: (data) => data,
    component: () => <NewsPage/>
}

export const newsPageDispatchingForPreview: PageDispatchingModel<NewsModelData, NewsModelApiCustomView> = {
    wagtail_model_name: NEWS_PAGE_WAGTAIL_MODEL_NAME,
    serializer: (data, language) =>  convertNewsModelDataToNewsModelViewForCustomNewsApiForPreview(data, language),
    component: (view) => <NewsTemplatePreview data={view}/>,
    schemaValidation: newsValidationSchema
}