import {useApiQuery} from "../../data-access/data-caching";
import {useContext, useMemo} from "react";
import ApiCallContext from "../../data-access/api-call/ApiCallContext";
import {
    CaptchaModelData,
    captchaModelDataSchema
} from "../../data-access/api-call/rest-captcha-api/captcha/models/CaptchaModelData";
import {mapper} from "../../utils/mapper/mapper";

export const GET_CAPTCHA_QUERY_KEY = "get_captcha";

/**
 * Description - Method to convert captcha model data to model view
 * Remark - The model for data and view is th same, the serializer inject api domain in image url and audio url
 * @param data
 */
export const convertCaptchaModelDataToCaptchaModelView = (data: CaptchaModelData): CaptchaModelData => {
    return mapper(data, {
        "key": "key",
        "image_url": {
            key: "image_url",
            serializer: (value: string) => `${process.env.REACT_APP_API_DOMAIN}${value}`
        },
        "audio_url": {
            key: "audio_url",
            serializer: (value: string) => `${process.env.REACT_APP_API_DOMAIN}${value}`
        }
    })
}

/**
 * Description - Service to fetch captcha from rest api
 */
export const useGetCaptcha = () => {

    const {getCaptcha} = useContext(ApiCallContext)

    const {data, isLoading, error, refetch} = useApiQuery<CaptchaModelData>(GET_CAPTCHA_QUERY_KEY, getCaptcha, {schemaValidation: captchaModelDataSchema})

    const view = useMemo(() => {
        return !isLoading && data ? convertCaptchaModelDataToCaptchaModelView(data) : undefined
    },[isLoading, data])


    return {data: view, isLoading, error, refetch}
}