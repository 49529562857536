import React from 'react'
import {Form} from "react-bootstrap";
import {SelectOption} from "../models";
import OverlayToolTip from "../../tooltip/OverlayToolTip";

export interface RadiosProps {
    options: Array<SelectOption>;
    onChange: (value: any) => void;
    value: any
}

const Radios: React.FC<RadiosProps> = ({options, onChange, value}) => {
    return <React.Fragment>
        {options.map(({label, value: valueOption, tooltip}) =>
            <Form.Check key={valueOption} inline={true}>
                <Form.Check.Input
                    aria-label={label}
                    key={valueOption}
                    checked={valueOption === value}
                    type="radio"
                    onChange={() => onChange(valueOption)}

                />

                        <Form.Check.Label>
                            { tooltip
                                ? <OverlayToolTip
                                    label={tooltip}
                                >
                                    {label}
                                </OverlayToolTip>
                                : label
                            }
                        </Form.Check.Label>

            </Form.Check>
        )}
    </React.Fragment>
}

export default Radios