import axios, {AxiosError} from "axios";
import {onFulfilled, onInterceptRequest, onRejected} from "../utils";

if(!process.env.REACT_APP_API_CUSTOM_URL) {
    throw Error("API_CUSTOM_URL variable is missing")
}

/**
 * Description - Instance of axios with main configuration for subscription
 */
const apiWagtailSubscribeClient = axios.create({
    baseURL: process.env.REACT_APP_API_CUSTOM_URL,
    timeout: 5000
})

apiWagtailSubscribeClient.interceptors.request.use((config) => {
    onInterceptRequest(config)
    return config
})

// api wagtail client response interception to manage a different case
apiWagtailSubscribeClient.interceptors.response.use(onFulfilled , (error: AxiosError) => {
    if(error.code === 'ERR_BAD_REQUEST' && error.response?.data) {
        return Promise.reject({
            type: "validation",
            message: {
                title: "error to with form value to subscribe",
                content: error.response.data
            }
        })
    }
    return onRejected(error)
})

export default apiWagtailSubscribeClient;