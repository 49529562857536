import React from 'react';
import ReactDOM from 'react-dom/client';
import './theming/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query'
import {ApiCallProvider} from "./data-access/api-call/ApiCallContext";
import ApiCall from "./data-access/api-call/ApiCall";
import TranslationProvider from "./translation/TranslationProvider";
import {NavigationServiceProvider} from "./components/navigation/navigation-service/NavigationService";
import {PreviewServiceProvider} from "./preview/PreviewService";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
      <PreviewServiceProvider>
          <NavigationServiceProvider>
              <TranslationProvider>
                  <ApiCallProvider value={ApiCall}>
                      <QueryClientProvider client={queryClient}>
                          <App/>
                      </QueryClientProvider>
                  </ApiCallProvider>
              </TranslationProvider>
          </NavigationServiceProvider>
      </PreviewServiceProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export {serializeVariables} from "./data-access/api-call/utils";