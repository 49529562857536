import {
    themePageLightModelDataSchema,
    ThemePageModelData,
    //themePageModelDataSchema,
    ThemePageServiceModelData
} from "./models/ThemePageModelData";
import {ThemePageModelView} from "./models/ThemePageModelView";
import {mapper} from "../../../../utils/mapper/mapper";
import {ApiImageData} from "../../../../data-access/api-call/wagtail-rest-page-api/image/models/ImageModel";
import {convertApiImageDataToImageAttributs} from "../../../../data-access/api-call/wagtail-rest-page-api/utils";
import {convertServiceModelDataToServiceModelView} from "../../../service/ServiceData";
import {useApiQuery} from "../../../../data-access/data-caching";
import {useContext, useMemo} from "react";
import ApiCallContext from "../../../../data-access/api-call/ApiCallContext";
import {GetPagesData} from "../../../../data-access/api-call/wagtail-rest-page-api/page";
import {useTranslationService} from "../../../../translation/TranslationProvider";

export const THEME_PAGE_WAGTAIL_MODEL_NAME = "theme.ThemePage"
export const THEME_PAGE_QUERY_NAME = "get_theme_page"
export const THEME_PAGE_MODEL_NAME = "Theme Page"

/**
 * Description - Method to convert theme page data model to theme page view model
 * @param data
 * @param locale
 */
export const convertThemePageDataToThemePageView = (data: ThemePageModelData, locale?: string): ThemePageModelView => {
    if(!locale){
        throw Error("locale is required to serialize theme page data")
    }
    return mapper(data, {
        "id": {
            key: "id",
            serializer: (value: number) => value.toString()
        },
        "title": "title",
        "description": "description",
        "image": {
            key: "icon",
            serializer: (value: ApiImageData) => convertApiImageDataToImageAttributs(value)
        },
        "services": {
            optional: true,
            key: "theme_service",
            serializer: (value: Array<ThemePageServiceModelData>) => value.map(({service_page}) => convertServiceModelDataToServiceModelView(service_page, locale))
        },
    })
}

/**
 * Description - Service to fetch theme page from wagtail rest api
 */
export const useGetThemePage = () => {
    const {language} = useTranslationService()
    const {getPages} = useContext(ApiCallContext)
    const {data, isLoading, error} = useApiQuery<GetPagesData<ThemePageModelData>>(THEME_PAGE_QUERY_NAME, getPages, {
        variables: {
            fields: ["title", "description", "icon"],
            type: THEME_PAGE_WAGTAIL_MODEL_NAME,
            order: "theme"
        },
        schemaValidation: themePageLightModelDataSchema,
        model: THEME_PAGE_MODEL_NAME
    })
    const themePageView = useMemo(() => {
        return data && !isLoading ? data.items.map(themePageData => convertThemePageDataToThemePageView(themePageData, language)) : undefined
    },[data, isLoading, language])

    return {data: themePageView, isLoading, error}
}