import axios from "axios";
import {onFulfilled, onInterceptRequest, onRejected, serializeVariables} from "../utils";

if(!process.env.REACT_APP_API_URL) {
    throw Error("the API_URL variable is missing")
}

/**
 * Description - Instance of axios with main configuration for wagtail rest api calling
 */
const apiWagtailClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000,
    paramsSerializer: serializeVariables
})

apiWagtailClient.interceptors.request.use((config) => {
    onInterceptRequest(config)
    return config
})

// api wagtail client response interception to manage a different case
apiWagtailClient.interceptors.response.use(onFulfilled , onRejected)

export default apiWagtailClient;