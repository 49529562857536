import React, { useCallback } from "react"
import { Button, Form, Stack } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import GDPRDialog from "../../../../../../gdpr/GDPRDialog"
import { useSwitch } from "../../../../../../ui/collapse/panel-expandable/PanelExpandable"

export interface RegistrationFormGdprPropsType {
    onConfirm: (confirm: boolean) => void
}

/**
 * Decription - UI Component to render Gdpr component and manage user gdpr confirmation in registration form
 * @returns 
 */
const RegistrationFormGdpr: React.FC<RegistrationFormGdprPropsType> = ({ onConfirm }) => {

    const { t } = useTranslation()
    const { onClose, onOpen, open } = useSwitch()

    const handleConfirm = useCallback((e: any) => {
        onConfirm(e.target.checked)
    }, [onConfirm])

    return <div>
        <Stack className="form-group" direction="horizontal">
            <Button className="margin-right-2" variant="primary" onClick={onOpen}>{t("page.registration-page.data-protection-button-label")}</Button>
            <Form.Check
                role="checkbox"
                type="checkbox"
                id="confirm-gdpr"
                label={t("page.registration-page.form.confirm-gdpr-field-label")}
                onChange={handleConfirm}
            />
        </Stack>
        <GDPRDialog open={open} onClose={onClose} />
    </div>
}

export default RegistrationFormGdpr