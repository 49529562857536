import React, {useCallback, useEffect, useMemo, useState} from "react";
import {UseFormRegisterReturn} from "react-hook-form";
import {Button, Form, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useGetCaptcha} from "../CaptchaData";
import ComponentSwitch from "../../ui/component-switch/ComponentSwitch";

export interface CaptchaFormFieldViewProps {
    audio_url: string;
    image_url: string;
    register: UseFormRegisterReturn;
    errorMessage?: string;
    refresh?: () => void
}

export const labelsKeys = {
    textFormFieldLabel: "ui.form.captchaFormField.placeholder",
    modeImageRadioLabel: "ui.form.captchaFormField.modeImageRadioLabel",
    modeAudioRadioLabel: "ui.form.captchaFormField.modeAudioRadioLabel",
    captchaInvalidCode: "ui.form.captchaFormField.captchaInvalidCode",
    captchaRefresh: "ui.form.captchaFormField.refreshButtonLabel"
}

export const testIds = {
    captchaImage: "form-field-captcha-image",
    captchaAudio: "form-field-captcha-audio"
}

export type CaptchaMode = "image" | "audio"


/**
 * Description - View of captcha form field
 * @param image_url
 * @param audio_url
 * @param errorMessage
 * @param register
 * @param refresh
 * @constructor
 */
export const CaptchaFormFieldView: React.FC<CaptchaFormFieldViewProps> = ({image_url, audio_url, errorMessage, register, refresh}) => {

    const {t} = useTranslation()

    const [mode, setMode] = useState<CaptchaMode>("image")

    const onChangeMode: React.ChangeEventHandler<HTMLInputElement> = useCallback((event )  => {
        if(event.target.value === "image" || event.target.value === "audio"){
            setMode(event.target.value)
        } else {
            console.error(`Select value: ${event.target.value} could be equal to "image" or "audio"`)
        }
    },[])

    return <div className="captcha-form-field margin-right-1 margin-left-1 padding-top-2 padding-bottom-2 margin-top-2">
        <div>
            <div className="captcha-media-container">
                {mode === "image"
                    ? <div className="image-container padding-right-1 padding-left-1">
                        <img data-testid={testIds.captchaImage} src={image_url} alt="captcha"/>
                    </div>
                    : <audio
                        // firefox, Safari doesn't support controlList property, by default Firefox and Safari don't have download feature in audi player
                        // disable download feature
                        controlsList="nodownload"
                        controls
                        src={audio_url}
                        data-testid={testIds.captchaAudio}
                    >
                    </audio>
                }
            </div>
            <div className="margin-top-1 margin-bottom-1">
                {refresh !== undefined && <Button onClick={refresh}>{t(labelsKeys.captchaRefresh)}</Button>}
            </div>
            <div className="margin-top-1">
                <Form.Check
                    id="captcha-mode-image"
                    role="radio"
                    checked={mode === "image"}
                    inline
                    type="radio"
                    label={t(labelsKeys.modeImageRadioLabel)}
                    value="image"
                    onChange={onChangeMode}
                    name="captcha-mode"
                />
                <Form.Check
                    id="captcha-mode-audio"
                    role="radio"
                    checked={mode === "audio"}
                    inline
                    type="radio"
                    label={t(labelsKeys.modeAudioRadioLabel)}
                    value="audio"
                    onChange={onChangeMode}
                    name="captcha-mode"
                />
            </div>
        </div>
        <Form.Group className="form-group padding-top-1" controlId="captcha-control">
            <Form.Label>
                {t(labelsKeys.textFormFieldLabel)}
            </Form.Label>
            <span>{" *"}</span>
            <Form.Control
                role="textbox"
                placeholder=""
                isInvalid={errorMessage !== undefined}
                type="text"
                {...register}
            />
            {errorMessage !== undefined && <Form.Text
              className="text-danger"

            >
                {t(errorMessage)}
            </Form.Text>}
        </Form.Group>
    </div>
}
export interface CaptchaFormFieldProps {
    setKey: (key: string) => void;
    register: UseFormRegisterReturn;
    errors?: {
        value?: string;
        key?: string;
    }
    errorFromBackend?: boolean;
}

/**
 * Description - UI Component for captcha
 *  - get captcha from rest api
 *  - manage validation of form field
 *  - render captcha form field view
 * @param register
 * @param errors
 * @param setKey
 * @param errorFromBackend
 * @constructor
 */
const CaptchaFormField: React.FC<CaptchaFormFieldProps> = ({register, errors, setKey, errorFromBackend}) => {

    const {data, isLoading, error, refetch } = useGetCaptcha()

    const errorMessage = useMemo(() => {
        if(errorFromBackend){
            return labelsKeys.captchaInvalidCode
        }
        if(errors?.value){
            return errors.value
        }
        if(errors?.key){
            return errors.key
        }
        return undefined
    }, [errors, errorFromBackend])

    useEffect(() => {
        if(data) {
            setKey(data.key)
        }
    },[data, setKey])

    const refreshCaptcha = useCallback(() => {
        if(refetch){
            refetch()
        }
    }, [refetch])

    return <ComponentSwitch loading={isLoading} loadingComponent={<Spinner animation="border"/>} error={error}>
        {!isLoading && data ? <CaptchaFormFieldView refresh={refreshCaptcha} audio_url={data.audio_url} image_url={data.image_url} register={register} errorMessage={errorMessage}/> : null}
    </ComponentSwitch>
}

export default CaptchaFormField;