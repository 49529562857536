import React, { useMemo} from "react";
import {useGetThemes} from "../../../../../theme/ThemeData";
import {Spinner} from "react-bootstrap";
import ComponentSwitch from "../../../../../ui/component-switch/ComponentSwitch";
import {SelectOption} from "../../../../../ui/form/models";
import MultiSelect from "../../../../../ui/form/select/MultiSelect";
import {useTranslation} from "react-i18next";
import {labelKeys} from "./RegistrationForm";

export interface ThemeMultiSelectProps {
    onChange: (value: Array<number>) => void
    value: Array<number>
    required?: boolean
    errorMessage?: string
}

/**
 * Description - UI Component form field to select multiple theme
 * @param onChange
 * @param value
 * @param required
 * @param errorMessage
 * @constructor
 */
const ThemeMultiSelect: React.FC<ThemeMultiSelectProps> = ({onChange, value, required, errorMessage}) => {

    const {t} = useTranslation()
    const {data, isLoading, error} = useGetThemes()

    const themeOptions: Array<SelectOption> = useMemo(() => {
        return data !== undefined && !isLoading
            ? data.map(({id, name}) => ({value: id, label: name}))
            : []
    }, [data, isLoading])

    return <ComponentSwitch loading={isLoading} loadingComponent={<Spinner animation="grow"/>} error={error}>
        {themeOptions.length > 0 && <MultiSelect required allSelectedByDefault options={themeOptions} value={value} onChange={onChange} label={t("term.theme")} placeholder={t(labelKeys.themePlaceholder)} errorMessage={errorMessage}/>}
    </ComponentSwitch>
}

export default ThemeMultiSelect;

