import {GetPagesData, GetPagesItemData, GetPagesVariables} from "./models/PageModel";
import {apiWagtailClient} from "../index";
import {ErrorApplicationData} from "../../../../models/error/ErrorModel";
import {ObjectSchema} from "yup";
import {validatePagesItem} from "../models/ApiCallValidationItemModel";
import {AxiosResponse} from "axios";
import {NewsModelData} from "../../../../components/news/models/NewsModelData";
import {LANDING_PAGE_WAGTAIL_MODEL_NAME} from "../../../../components/page/page-model/landingPage/LandingPageData";

export const GET_PAGE_PATH = !process.env.REACT_APP_PREVIEW_MODE || process.env.REACT_APP_PREVIEW_MODE === "false" ? "/pages/" : "/pages_previews/" ;

export const GET_NEWS_PATH = "/news/";

/**
 * Description - Method to resolve response data from api
 * @param response
 * @param resolve
 * @param reject
 * @param schemaValidation
 */
export const resolveResponseData = <TData extends GetPagesData = GetPagesData>(
    response: AxiosResponse<TData>,
    resolve: (value: TData | PromiseLike<TData> | undefined) => void,
    reject: (error?: ErrorApplicationData) => void,
    schemaValidation?: ObjectSchema<any>
) => {
    if(!response.data.items.length){
        const noContentError: ErrorApplicationData = {
            type: "no-content",
            message: "they are no content for this model",
        }
        reject(noContentError)
    } else {
        if(schemaValidation){
            validatePagesItem(schemaValidation, response.data).then(() => resolve(response.data)).catch(error => reject(error))
        } else {
            resolve(response.data)
        }

    }
}

/**
 * Description - Process to get news filtered with variables from custom wagtail news rest api
 * @param variables
 * @param schemaValidation
 */
export const getNews = (variables: GetPagesVariables, schemaValidation?: ObjectSchema<any>): Promise<GetPagesData<NewsModelData> | undefined> => {
    return new Promise<GetPagesData<NewsModelData> | undefined>((resolve, reject) => {
        apiWagtailClient.get<GetPagesData<NewsModelData>>(GET_NEWS_PATH, {
            params: variables
        }).then((response => {
            resolveResponseData(response, resolve, reject, schemaValidation)
        })).catch((error) => {
            reject(error)
        })
    })
}

/**
 * Description - Process to get pages by variable from wagtail rest api
 * @param variables
 * @param schemaValidation
 */
export const getPages = (variables: GetPagesVariables, schemaValidation?: ObjectSchema<any>): Promise<GetPagesData | undefined> => {
    return new Promise<GetPagesData | undefined>((resolve, reject) => {
        apiWagtailClient.get<GetPagesData>(GET_PAGE_PATH, {
            params: variables,
        }).then((response => {
            resolveResponseData(response, resolve, reject, schemaValidation)
        })).catch(error => reject(error))
    })
}

/**
 * Description - Process to get page by id from wagtail rest api
 * @param id
 */
export const getPage = (id: string): Promise<GetPagesItemData | undefined> => {
    return new Promise((resolve, reject) => {
        apiWagtailClient.get(`${GET_PAGE_PATH}${id}`).then((response: AxiosResponse<GetPagesItemData>) => {
            resolve(response.data)
        }).catch(error => {
            console.error(error)
            reject(error)
        })
    })
}

/**
 * Decription - Process to get page translation id by page id and locale
 * @param id
 * @param locale
 */
export const getTranslationId = (id: string, locale: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const params: GetPagesVariables = {
            locale,
            translation_of: id,
        }
        apiWagtailClient.get(`${GET_PAGE_PATH}`,{
            params
        }).then((response: AxiosResponse<GetPagesData>) => {
            if(response.data.items[0].id){
                resolve(response.data.items[0].id.toString())
            } else {
                const error: ErrorApplicationData = {
                    type: "no-content",
                    message: "They are no translation"
                }
                reject(error)
            }

        }).catch(error => {
            console.error(error)
            reject(error)
        })
    })
}

/**
 * Description - Process to verify if they are content for each language. The strategy is to check if they are landing page for each language
 */
export const verifyTranslationContent = (): Promise<Array<string | undefined>> => {
    return new Promise((resolve, reject) => {
        apiWagtailClient.get(`${GET_PAGE_PATH}`, {params: {
                type: LANDING_PAGE_WAGTAIL_MODEL_NAME,
            }}).then((response: AxiosResponse<GetPagesData>) => {
            resolve(response.data.items.map(item => item.meta.locale))
        }).catch(error => {
            console.error(error)
            reject(error)
        })
    })
}