import {GetPagesItemData} from "../../../data-access/api-call/wagtail-rest-page-api/page";
import * as yup from "yup"
import {requiredMessage} from "../../../models/error/ModelErrorValidation";
import {ObjectSchema} from "yup";
import {
    ApiImageData,
    apiImageDataSchema
} from "../../../data-access/api-call/wagtail-rest-page-api/image/models/ImageModel";

export interface HeaderModelData extends GetPagesItemData{
    title: string;
    subtitle: string;
    logo: ApiImageData;
    background_image: ApiImageData;
}

// @ts-ignore
export const headerDataSchema: ObjectSchema<any> = yup.object({
    title: yup.string().required(requiredMessage),
    subtitle: yup.string().required(requiredMessage),
    logo: apiImageDataSchema.required(requiredMessage),
    background_image: apiImageDataSchema.required(requiredMessage),
})