/**
 * Description - Type for different error
 *  - run: application crash
 *  - no-content: don't find content in data access
 *  - validation: object validation failed
 *  - api-problem: problem from api
 *  - api-bad-request: bad request from api
 */
export type ErrorType = "run" | "no-content" | "validation" | "api-problem" | "api-no-access" | "api-bad-request"

export interface ErrorApplicationData<T extends Object= Object> {
    type: ErrorType,
    model?: string,
    message: string | {
        title?: string
        content: T
    }
}

export class ErrorApplication extends Error {
    public type: ErrorType;
    constructor(message: any, type: ErrorType) {
        super(message);
        this.type = type;
    }
}