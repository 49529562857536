import React from "react";
import RegistrationForm from "./ui/registration-form/RegistrationForm";
import SpacingMenu from "../../../ui/spacing/SpacingMenu";
import {Container} from "react-bootstrap";
import {RegistrationPageModelData} from "./models/RegistrationPageModelData";
export interface RegistrationPageViewProps {
    data: RegistrationPageModelData
}

/**
 * Description - View Component to display registration page information and render registration form
 * @param title
 * @param subtitle
 * @constructor
 */
const RegistrationPageView: React.FC<RegistrationPageViewProps> = ({data: {title}}) => {

    return <SpacingMenu>
       <Container className="registration-page padding-top-1">
           <h1>{title}</h1>
           </Container>
        <RegistrationForm/>
    </SpacingMenu>
}

export default RegistrationPageView