import {NewsModelApiCustomView} from "../../news/models/NewsModelView";
import React from "react";
import NewsList from "../../page/page-model/news-page/ui/news-list/NewsList";
import SpacingMenu from "../../ui/spacing/SpacingMenu";
import NewsSearchForm from "../../page/page-model/news-page/ui/news-search-form/NewsSeachForm";

export interface NewsTemplateProps {
    data: NewsModelApiCustomView
}

/**
 * Description - UI Component to render news for preview
 * @param data
 * @constructor
 */
const NewsTemplatePreview: React.FC<NewsTemplateProps> = ({data}) => {
    return  <SpacingMenu>
        <NewsSearchForm onSearch={() => {}}/>
        {//@ts-ignore
        <NewsList data={[data]} selectTheme={() => {}}  selectTag={() => {}}/>
        }
    </SpacingMenu>
}

export default NewsTemplatePreview;