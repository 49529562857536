import {PageDispatchingModel} from "../../../page/page-dispatching/models/PageDispatchingModel";
import {MainMenuModelData, menuModelDataSchema} from "../models/MainMenuModelData";
import {MainMenuModelView} from "../models/MainMenuModelView";
import {convertModelDataToMenuModelView, MAIN_MENU_WAGTAIL_MODEL_NAME} from "../MainMenuData";
import LandingPage from "../../../page/page-model/landingPage/LandingPage";

export const mainMenuDispatcherForPreview: PageDispatchingModel<MainMenuModelData, MainMenuModelView> = {
    wagtail_model_name: MAIN_MENU_WAGTAIL_MODEL_NAME,
    serializer: convertModelDataToMenuModelView,
    component: () => <LandingPage/>,
    schemaValidation: menuModelDataSchema
}