import * as yup from "yup";
import {requiredMessage} from "../../../../../models/error/ModelErrorValidation";

export interface GetImageData
{
    id: number;
    meta: GetImageMeta,
    title: string;
    width: number;
    height: number;
}

export interface ApiImageMeta {
    type: "wagtailimages.Image",
    detail_url: string;
    download_url: string;
}

export const apiImageMetaSchema = yup.object({
    type: yup.string(),
    detail_url: yup.string().required(requiredMessage),
    download_url: yup.string().required(requiredMessage)
})
/**
 * Description - Type base API Image
 */
export type ApiImageData = {
    id: number;
    title: string;
    meta: ApiImageMeta
}
export const apiImageDataSchema = yup.object({
    id: yup.number().required(requiredMessage),
    title: yup.string().required(requiredMessage),
    meta: apiImageMetaSchema.required(requiredMessage)
})

export interface GetImageMeta extends ApiImageMeta{
    "tags": Array<any>
}