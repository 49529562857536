import apiWagtailSnippetClient from "../ApiWagtailSnippetClient";
import {ErrorApplicationData} from "../../../../models/error/ErrorModel";
import {GetActorData, getActorSchema} from "./models/ActorModel";

export const GET_ACTOR_API_URL = "/actors"

/**
 * Description - Process to fetch get actor by id from wagtail rest api snippet
 */
export const getActor = (id: string): Promise<GetActorData> => {
    return new Promise<GetActorData>((resolve, reject)=> {
        apiWagtailSnippetClient.get<GetActorData>(`${GET_ACTOR_API_URL}/${id}`).then(response => {
            if(!response.data){
                const noContentError: ErrorApplicationData = {
                    type: "no-content",
                    message: "they are no content for this model",
                }
                reject(noContentError)
            } else {
                getActorSchema.validate(response.data).then(() => resolve(response.data)).catch(error => reject(error))
            }

        }).catch(error => reject(error))
    })
}