import {DefaultProviderModel} from "../../../utils/provider/DefaultProviderModel";
import {ErrorApplicationData} from "../../../models/error/ErrorModel";
import React, {ReactElement, useMemo, useRef} from "react";
import FadeTransition from "../transition/FadeTransition";
import {useGetErrorComponent} from "../../error-bounderies/ErrorBoundaries";

export interface ComponentSwitchProps extends DefaultProviderModel {
    error?: ErrorApplicationData;
    loading: boolean;
    loadingComponent: ReactElement | null;
    isPage?: boolean;
    emptyComponent?: ReactElement | null;
    errorComponent?: ReactElement | null;
}

/**
 * Description - UI Component to switch between error, loading, or success component (children)
 * @param children
 * @param loading
 * @param loadingComponent
 * @param error
 * @param isPage
 * @param emptyComponent
 * @param errorComponent
 * @constructor
 */
const ComponentSwitch: React.FC<ComponentSwitchProps> = ({children, loading, loadingComponent, error, isPage, emptyComponent, errorComponent}) => {

    const errorDefaultComponent = useGetErrorComponent({error, isPage, emptyComponent})

    const errorResultComponent = useMemo(() =>  {
        return errorComponent
            ? errorComponent
            : errorDefaultComponent
    }, [errorComponent, errorDefaultComponent])

    const loadingRef = useRef(null);
    const errorRef = useRef(null);
    const successRef = useRef(null)

    const state: "error" | "loading" | "success" = useMemo(() => {
        if(error){
            return "error"
        }
        if(loading){
            return "loading"
        }
        return "success"

    },[loading, error])

    const nodeRef = useMemo(() => {
        if(error){
            return errorRef
        }
        if(loading){
            return loadingRef
        }
        return successRef
    }, [error, loading])

    return <div style={{width: "100%"}}>
        <FadeTransition
            activeKey={state}
            nodeRef={nodeRef}
        >
            {error
                ? errorResultComponent
                : loading
                    ? loadingComponent
                    : children
            }
        </FadeTransition>
    </div>
}

export default ComponentSwitch