import {
    ServiceModelData,
    ServicePageModelData,
    ThemeServiceModelData
} from "./models/ServiceModelData";
import {ServiceModelView} from "./models/ServiceModelView";
import {mapper} from "../../utils/mapper/mapper";
import {ApiImageData} from "../../data-access/api-call/wagtail-rest-page-api/image/models/ImageModel";
import {convertApiImageDataToImageAttributs} from "../../data-access/api-call/wagtail-rest-page-api/utils";
import {
    linkForPreview,
    themePagesInfoForPreview
} from "../preview/service-template-preview/ServiceTemplatePreview";
import {LinkApplication} from "../../models/general_model";

export const THEME_SERVICE_WAGTAIL_MODEL_NAME = "theme.ServicePage"

export const THEME_SERVICE_INDEX_PAGE_WAGTAIL_MODEL_NAME = "theme.ServiceIndexPage"

/**
 * Description - Method to convert service data model to service view model
 * @param data
 * @param locale
 */
export const convertServiceModelDataToServiceModelView = (data: ServiceModelData, locale: string): ServiceModelView => {

    return mapper(data, {
        "title": "title",
        "description": "description",
        "image": {
            optional: true,
            key: "image",
            serializer: (value: ApiImageData) => convertApiImageDataToImageAttributs(value)
        },
        "link": {
            key: "link",
            serializer: (linkValue: string) => JSON.parse(linkValue).map(({value}: {value: LinkApplication}) => value)
        },
        "themes": {
            key: "theme_pages_infos",
            serializer: (value: Array<ThemeServiceModelData>) => value.filter((theme_pages_infos) => theme_pages_infos.locale === locale).sort((a, b) => a.theme.order > b.theme.order ? 1 : -1).map(theme_pages_infos => ({theme_page_id: theme_pages_infos.id.toString(), name: theme_pages_infos.theme.name}))
        }
    })
}

/**
 * Description - Method to convert service model data to service view model for preview
 * @param data
 */
export const convertServiceModelDataToServiceModelViewForPreview = (data: ServicePageModelData): ServiceModelView => {

    return {...mapper(data, {
            "title": "title",
            "description": "description",
            "image": {
                optional: true,
                key: "image",
                serializer: (value: ApiImageData) => convertApiImageDataToImageAttributs(value)
            },
        }), link: linkForPreview, themes: themePagesInfoForPreview}
}