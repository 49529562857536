import React from "react";
import {useTranslation} from "react-i18next";
import SpacingMenu from "../../../ui/spacing/SpacingMenu";
import MessagePanel from "../../../ui/panel/MessagePanel";

export const labelKeys = {
    message: "page.unsubscribe-page.panel-success.message",
    buttonNavigationLabel: "page.unsubscribe-page.panel-success.navigation-button"
}
/**
 * Description - View Component to display message in success context fot unsubscribe
 * @constructor
 */
const UnSubscribePage: React.FC = () => {

    const {t} = useTranslation()

    return <SpacingMenu>
        <MessagePanel message={t(labelKeys.message)} link={{url: "/", label: t(labelKeys.buttonNavigationLabel)}} variant="success"/>
    </SpacingMenu>
}

export default UnSubscribePage