import {apiWagtailClient} from "../index";
import {AxiosResponse} from "axios";
import {GetImageData} from "./models/ImageModel";

export const GET_IMAGE_PATH = "/images/"

/**
 * Description - Process to get image by id from wagtail rest api
 * @param id
 */
export const getImage = (id: string): Promise<GetImageData | undefined> => {
    return new Promise((resolve, reject) => {
        apiWagtailClient.get(`${GET_IMAGE_PATH}${id}`).then((response: AxiosResponse<GetImageData>) => {
            resolve(response.data)
        }).catch(error => reject(error))
    })
}