import {NewsModelApiCustomView} from "../../../../../news/models/NewsModelView";
import React from "react";
import NewsCard from "../news-card/NewsCard";
import {Container} from "react-bootstrap";

export interface NewsListProps {
    data: Array<NewsModelApiCustomView>,
    selectTheme: (id: number) => void,
    selectTag: (tagName: string) => void
}

/**
 * Description - View Component to display news list
 * @param data
 * @param selectTheme
 * @param selectTag
 * @constructor
 */
const NewsList: React.FC<NewsListProps> = ({data, selectTheme, selectTag}) => {
    return <Container>
        {
            data.map(news => (
                <NewsCard key={news.title} content={news} selectTheme={selectTheme} selectTag={selectTag}/>
            ))
        }
    </Container>
}

export default NewsList
