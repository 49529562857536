import {DefaultProviderModel} from "../utils/provider/DefaultProviderModel";
import PreviewContext, {PreviewContextProvider} from "./PreviewContext"
import React, {useContext, useMemo} from "react";

/**
 * Description - Provider to inject preview service
 *
 * Remark - It is an implementation of React context provider
 * @param children
 * @constructor
 */
export const PreviewServiceProvider: React.FC<DefaultProviderModel> = ({children}) => {
    const isPreview = useMemo(() => !!(process.env.REACT_APP_PREVIEW_MODE && process.env.REACT_APP_PREVIEW_MODE === "true"), [])

    return <PreviewContextProvider value={{isPreview}}>
        {children}
    </PreviewContextProvider>
}

/**
 * Description - Service to get preview service
 *
 * Remark - It is an implementation of React context hook
 */
export const usePreview = () => {
    return useContext(PreviewContext)
}