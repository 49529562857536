import {PageDispatchingModel} from "../../page/page-dispatching/models/PageDispatchingModel";
import {headerDataSchema, HeaderModelData} from "../models/HeaderModelData";
import {HeaderModelView} from "../models/HeaderModelView";
import {convertHeaderDataToHeaderView, HEADER_WAGTAIL_MODEL_NAME} from "../HeaderData";
import LandingPage from "../../page/page-model/landingPage/LandingPage";

export const headerDispatcherForPreview: PageDispatchingModel<HeaderModelData, HeaderModelView> = {
    wagtail_model_name: HEADER_WAGTAIL_MODEL_NAME,
    serializer: convertHeaderDataToHeaderView,
    component: () => <LandingPage/>,
    schemaValidation: headerDataSchema
}