import {AxiosRequestConfig} from "axios";
import {serializeVariables} from "./utils";

export interface LogResponse {
    urlComplete: string;
    headers: any
    data: any,
}

export interface LogRequest {
    urlComplete: string,
    url: string;
    method: string;
    paramsObject: any;
    params: string;
    headers: any;
}

/**
 * Description - Method to convert axios request config to log message request
 * @param config
 */
export const convertRequestConfigToLogRequest = (config: AxiosRequestConfig): LogRequest => {
    const {url, baseURL, method, params, headers} = config
    const paramsString: string = serializeVariables(params)
    const urlWithBase = `${baseURL}${url}`;
    const urlComplete = `${urlWithBase}?${paramsString}`
    return {
        urlComplete: urlComplete,
        url: urlWithBase,
        method: method ? method : "unknown",
        params: paramsString,
        paramsObject: params,
        headers
    }
}