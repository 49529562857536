import * as yup from "yup"
import {requiredMessage} from "../../../../../models/error/ModelErrorValidation";
import {ObjectSchema} from "yup";
import {GetPagesItemData} from "../../../../../data-access/api-call/wagtail-rest-page-api/page";

export interface RegistrationPageModelData extends GetPagesItemData{
    title: string;
    subtitle?: string;
}

// @ts-ignore
export const registrationPageModelDataSchema: ObjectSchema<any> = yup.object({
    title: yup.string().required(requiredMessage),
    subtitle: yup.string().nullable()
})