import React, {useCallback, useContext, useMemo, useState} from "react";
import {useApiQuery} from "../../../data-access/data-caching";
import {GetPagesData, GetPagesVariables} from "../../../data-access/api-call/wagtail-rest-page-api/page";
import {NewsModelApiCustomData, newsValidationApiCustomSchema} from "../models/NewsModelData";
import ComponentSwitch from "../../ui/component-switch/ComponentSwitch";
import {
    convertNewsModelApiCustomDataToNewsModelViewForCustomNewsApi,
    NEWS_PAGE_MODEL_NAME,
    NEWS_PAGE_QUERY_NAME, OnSearchVariables
} from "../NewsData";
import {Spinner} from "react-bootstrap";
import NewsList from "../../page/page-model/news-page/ui/news-list/NewsList";
import {getErrorComponent} from "../../error-bounderies/ErrorBoundaries";
import {NewsPageEmpty} from "../../page/page-model/news-page/NewsPage";
import {NewsModelApiCustomView} from "../models/NewsModelView";
import {useTranslationService} from "../../../translation/TranslationProvider";
import ApiCallContext from "../../../data-access/api-call/ApiCallContext";
import {ScrollInfinite} from "../../ui/scroll";

export interface NewsSearchDataBlockProps {
    variables: OnSearchVariables
    onNext: () => void
    selectTheme: (themeId: number) => void
    selectTag: (tag: string) => void
    isSingle? : boolean
}

export interface UseSearchNewsVariablesVariables {
    defaultVariables: GetPagesVariables
}

/**
 * Description - Service to manage global variables and variables package to generate news package s by page
 * @param defaultVariables
 */
export const useSearchNewsVariables = ({defaultVariables}: UseSearchNewsVariablesVariables) => {

    const [variables, setVariables] = useState<OnSearchVariables>(defaultVariables)
    const [newsPackagesVariables, setNewsPackagesVariables] = useState<Array<OnSearchVariables>>([defaultVariables])

    const onNext = useCallback(() => {
        setVariables(current => {
            if(current.offset !== undefined && current.limit !== undefined){
                const offset = current.offset + current.limit
                const newVariable: GetPagesVariables = {...current, offset}
                setNewsPackagesVariables((packageCurrent) => {
                    packageCurrent.push(newVariable)
                    return packageCurrent
                })
                return newVariable
            } else {
                console.error("the offset and limit property for pagination is required for this process")
                return current
            }
        })
    }, [])

    const onChangeTheme = useCallback((theme: string) => {
        setVariables(current => {
            window.scrollTo({ top: 0});
            const {tags, search, search_operator, offset, ...rest} = current
            const result = {...rest, offset: 0, theme}
            setNewsPackagesVariables([result])
            return result
        })
    }, [])

    const onChangeTag = useCallback((tag: string) => {
        setVariables(current => {
            window.scrollTo({ top: 0});
            const {tags, search, search_operator, offset, ...rest} = current
            let result: OnSearchVariables = {}
            if(tag !== ""){
                result = {...rest, offset: 0, tags: tag}
            } else {
                result =  {...rest, offset: 0,}
            }
            setNewsPackagesVariables([result])
            return result
        })
    }, [])

    const onChangeVariables = useCallback((newVariables: OnSearchVariables) => {
        setVariables(current => {
            window.scrollTo({ top: 0});
            const {tags, search, search_operator, ...rest} = current
            const result: OnSearchVariables = {...rest}
            if(newVariables.tags){
                result.tags = newVariables.tags
            } else {
                result.tags = undefined
            }
            if(newVariables.theme){
                result.theme = newVariables.theme
            } else {
                result.theme = undefined
            }
            if(newVariables.search){
                result.search = newVariables.search
                result.search_operator = "and"
            } else {result.search = newVariables.search
                result.search = undefined
                result.search_operator = undefined
            }
            setNewsPackagesVariables([result])
            return result
        })
    }, [])

    return {pages: newsPackagesVariables, variables, onChangeVariables, onChangeTag, onChangeTheme, onNext}
}

export const NewsSearchDataBlock: React.FC<NewsSearchDataBlockProps> = ({variables, selectTheme, onNext, selectTag, isSingle}) => {
    const {getNews} = useContext(ApiCallContext)
    const {data, isLoading, error} = useApiQuery<GetPagesData<NewsModelApiCustomData>>(NEWS_PAGE_QUERY_NAME, getNews, {
        variables,
        schemaValidation: newsValidationApiCustomSchema,
        model: NEWS_PAGE_MODEL_NAME,
    })

    const {language} = useTranslationService()

    const view = useMemo(() => {
        return data?.items !== undefined ? data.items.map(item => convertNewsModelApiCustomDataToNewsModelViewForCustomNewsApi(item, language)) : [] as Array<NewsModelApiCustomView>
    }, [data, language])

    return <ComponentSwitch loading={isLoading} loadingComponent={<Spinner/>} error={error} emptyComponent={isSingle ? <NewsPageEmpty/> : <div></div>}>
        <ScrollInfinite enableFromParent={!isLoading && variables.limit !== undefined && view.length === variables.limit}  onNext={onNext} onTimeUsed={true}>
            <NewsList data={view} selectTheme={selectTheme}  selectTag={selectTag}/>
            {isLoading && <div data-testid="page-loading-container" style={{minHeight: "100vh"}} className="align-content-center"><Spinner animation="border"/></div>}
            {error && error.type !== "no-content" && <div className="padding-top-1 padding-bottom-1">{getErrorComponent({error, isPage : false})}</div>}
        </ScrollInfinite>
    </ComponentSwitch>
}
