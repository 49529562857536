import {NEWS_DETAIL_PAGE_WAGTAIL_MODEL_NAME, NewsDetailModelData} from "../../../../news/NewsDetailModelData";
import {PageDispatchingModel} from "../../../page-dispatching/models/PageDispatchingModel";
import {NewsDetailPageView} from "../NewsDetailPage";
import {NewsModelDetailView} from "../../../../news/models/NewsModelView";

export const defaultNewsDetailPageDispatcher: PageDispatchingModel<NewsDetailModelData, NewsModelDetailView> = {
    wagtail_model_name: NEWS_DETAIL_PAGE_WAGTAIL_MODEL_NAME,
    serializer: (data: NewsDetailModelData, _locale) => data,
    component: (view: NewsModelDetailView) => <NewsDetailPageView data={view}/>
}

export const newsDetailPageDispatcherForPreview: PageDispatchingModel<NewsDetailModelData, NewsModelDetailView> = {
    wagtail_model_name: NEWS_DETAIL_PAGE_WAGTAIL_MODEL_NAME,
    serializer: (data: NewsDetailModelData, _locale) => data,
    component: (view: NewsModelDetailView) => <NewsDetailPageView data={view}/>
}