import React from "react";

export interface TagChipsProps {
    content: string;
    first?: boolean;
    lastWithInterval?: boolean;
    variant?: "accent" | "primary" | "secondary" | "secondary-light";
    clickable?: boolean
}

/**
 * Description - UI Component to render chips for tag
 * @param content
 * @param first
 * @param lastWithInterval
 * @param variant
 * @param clickable
 * @constructor
 */
const TagChips: React.FC<TagChipsProps> = ({content, first, lastWithInterval, variant, clickable}) => {
    // :first in css is not possible in this case
    return <span className={`chips tag${first ? " first":  ""}${lastWithInterval ? " last-with-interval" : ""}${clickable ? " clickable" : ""}`}><p className={`${variant ? variant : "primary"}`} >{content}</p></span>
}

export default TagChips;