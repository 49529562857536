import {
    RegistrationErrorsModelData,
    RegistrationModelData,

} from "./models/RegistrationModelData";
import {useContext, useMemo} from "react";
import ApiCallContext from "../../data-access/api-call/ApiCallContext";
import {useMutation} from "react-query";
import {ErrorApplicationData} from "../../models/error/ErrorModel";

export const REGISTRATION_MUTATION_KEY = "registration"

/**
 * Description - Service to return mutation for registration
 */
export const useRegistrationMutation = () => {
    const {onSubscribe} = useContext(ApiCallContext)

    const {mutate, error, isLoading, isSuccess} = useMutation<any, ErrorApplicationData<RegistrationErrorsModelData>, RegistrationModelData>(REGISTRATION_MUTATION_KEY, {mutationFn: onSubscribe})

    const errorApplication: RegistrationErrorsModelData | null = useMemo(() => {
        if(error && error !== null && error.type === "validation"){
            if(typeof error.message !== "string"){
                return error.message.content
            }
            console.error("In the validation context, the property content is required")
            return null
        }
        return null
    }, [error])

    const isCriticalError = useMemo(() => {
        if(error && error !== null && error.type !== "validation"){
            return true
        }
        return false
    },[error])

    return {mutate, error: errorApplication, isLoading, isSuccess, isCriticalError}
}