import * as yup from "yup"
import {requiredMessage} from "../../../../../../../models/error/ModelErrorValidation";
import {LandingPageGalleryModelView} from "./LandingPageGalleryModelView";
import {ImageAttribute} from "../../../../../../../models/general_model";
import {
    ApiImageData,
    apiImageDataSchema
} from "../../../../../../../data-access/api-call/wagtail-rest-page-api/image/models/ImageModel";
import {convertApiImageDataToImageAttributs} from "../../../../../../../data-access/api-call/wagtail-rest-page-api/utils";
export interface LandingPageGalleryModelData {
    id: number;
    meta: {
        type: "landing_page.LandingPageGalleryImage";
    };
    caption?: string;
    image: ApiImageData
}

export const landingPageGalleryModelDataSchema = yup.object({
    id: yup.string().required(requiredMessage),
    meta: yup.object({
        type: yup.string().required(requiredMessage)
    }).required(requiredMessage),
    caption: yup.string(),
    image: apiImageDataSchema.required(requiredMessage).nullable()
})

/**
 * Description - Method to convert Image Gallery model data for landing page to Image Gallery model view
 * @param data
 */
export const convertLandingPageGalleryModelDataToLandingPageGalleryModelView = (data: LandingPageGalleryModelData): LandingPageGalleryModelView => {
   const imageAttributs: ImageAttribute = convertApiImageDataToImageAttributs(data.image)
   const landingPageGalleryModelView: LandingPageGalleryModelView = {...imageAttributs};
   if(data.caption){
       landingPageGalleryModelView.caption = data.caption
   }
   return landingPageGalleryModelView;
}