import {PageDispatchingModel} from "../../../page-dispatching/models/PageDispatchingModel";
import {ThemePageModelData, themePageModelDataSchema} from "../models/ThemePageModelData";
import {ThemePageModelView} from "../models/ThemePageModelView";
import {convertThemePageDataToThemePageView, THEME_PAGE_WAGTAIL_MODEL_NAME} from "../ThemePageData";
import {ThemePageView} from "../ThemePage";

export const defaultThemePageDispatching: PageDispatchingModel<ThemePageModelData, ThemePageModelView> = {
    wagtail_model_name: THEME_PAGE_WAGTAIL_MODEL_NAME,
    serializer: convertThemePageDataToThemePageView,
    component: (view) => <ThemePageView data={view}/>,
    schemaValidation: themePageModelDataSchema
}