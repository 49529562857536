import React from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import RichText from "../../../../../ui/RichText/RichText";
import {useGetThemePage} from "../../../theme-page/ThemePageData";
import ComponentSwitch from "../../../../../ui/component-switch/ComponentSwitch";
import {useNavigate} from "react-router-dom";
import {usePreview} from "../../../../../../preview/PreviewService";

/**
 * Description - View Component to render landing page card
 * @constructor
 */
const LandingPageCard: React.FC= () => {

    const {data, isLoading, error} = useGetThemePage()
    const navigate = useNavigate()
    const {isPreview} = usePreview()

    return <div className="LandingPageCard">
        <Container>
            <ComponentSwitch loading={isLoading} loadingComponent={<Spinner animation="border"/>} error={error}>
                {data !== undefined && <Row>
                    {data.map(({title, description, image, id}) => (
                        <Col key={title} md={12} lg={4}>
                            <span onClick={!isPreview ? () => navigate(`/${id}`) : undefined} className={`landing-page-card${!isPreview ? " active" : ""}`}>
                                <img
                                    className="ms-auto"
                                    src={image.imageUrl}
                                    alt={image.altText}
                                />
                                <h4>{title}</h4>
                                <div className="landing-page-card-content padding-top-1 padding-right-1 padding-bottom-1 padding-left-1">
                                    <RichText content={description}/>
                                    <div className="cache-end-text"></div>
                                </div>
                            </span>
                        </Col>
                    ))}
                </Row>}
            </ComponentSwitch>
        </Container>
    </div>
}

export default LandingPageCard