import * as yup from "yup"
import {requiredMessage} from "../../../../../models/error/ModelErrorValidation";

export interface GetThemeItemData {
    id: number;
    name: string;
    locale: string;
    order?: number;
}

export interface GetThemesData {
    count: number,
    next: any,
    previous: any,
    results: Array<GetThemeItemData>
}

export const getThemeItemDataSchema = yup.object({
    id: yup.number().required(requiredMessage),
    name: yup.string().required(requiredMessage),
    locale: yup.string().required(requiredMessage)
})

export const getThemesDataSchema = yup.object({
    count: yup.number().required(requiredMessage),
    next: yup.object().required(requiredMessage).nullable(),
    previous: yup.object().required(requiredMessage).nullable(),
    results: yup.array(getThemeItemDataSchema).required(requiredMessage).nullable()
}).nullable()