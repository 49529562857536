import axios from "axios";
import {onFulfilled, onInterceptRequest, onRejected, serializeVariables} from "../utils";

if(!process.env.REACT_APP_API_URL) {
    throw Error("the API_URL variable is missing")
}

/**
 * Description - Instance of axios with main configuration
 */
const apiCaptchaClient = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN,
    timeout: 5000,
    paramsSerializer: serializeVariables,
    // add headers to inform api that the call come from frontend with XML Http Request
    headers: ({"x-requested-with": "XMLHttpRequest"})
})

apiCaptchaClient.interceptors.request.use((config) => {
    onInterceptRequest(config)
    return config
})

// api client response interception to manage a different case
apiCaptchaClient.interceptors.response.use(onFulfilled , onRejected)

export default apiCaptchaClient;