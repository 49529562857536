import React, {useCallback} from "react";
import {Form} from "react-bootstrap";
import {UseFormRegisterReturn} from "react-hook-form";
import {SelectOption} from "../models";

export interface SelectProps{
    options: Array<SelectOption>
    emptyOption?: boolean;
    labelEmptyOption?: string
    placeholder?: string;
    register?: UseFormRegisterReturn;
    onChange?: (value: number | "") => void
}

const Select: React.FC<SelectProps> =({options, emptyOption, placeholder, register, labelEmptyOption, onChange}) => {

    const handleChange = useCallback((event?: any) => {
        if(onChange){
            console.log(event)
        }
    },[onChange])

    return <Form.Control
        onChange={handleChange}
        as="select"
        // @ts-ignore
        placeholder={placeholder ? placeholder : undefined} {...register}>
        <option hidden disabled key="null" value="">{placeholder}</option>
        {emptyOption && <option key={-1} value={""}>{labelEmptyOption}</option>}
        {options.map(({value, label}) => (
            <option key={value} value={value} >{label}</option>
        ))
        }
    </Form.Control>
}

export default Select