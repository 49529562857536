import React from "react";
import {NewsModelView} from "../../../../../news/models/NewsModelView";
import {Carousel} from "react-bootstrap";
import {useGetLastNews} from "../../../../../news/NewsData";
import ComponentSwitch from "../../../../../ui/component-switch/ComponentSwitch";
import PageLoading from "../../../../PageLoading";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {usePreview} from "../../../../../../preview/PreviewService";

export interface LandingPageNewsSectionViewProps {
    data: Array<NewsModelView>
}

/**
 * Description - View component to display news in landing page
 * @param data
 * @constructor
 */
export const LandingPageNewsSectionView: React.FC<LandingPageNewsSectionViewProps> = ({data}) => {
    const {isPreview} = usePreview()
    const {t} = useTranslation()
    return <React.Fragment>
        <Carousel className="landing-page-news-sections">
            {data.map(({image, title, published_date, url, detail_page_id}) => (
                <Carousel.Item
                    key={`${title}${published_date}`}
                    style={ image ? {backgroundImage: `url(${image.imageUrl})`} : {backgroundColor: "black"}}
                    className="background"
                >
                    <div className="background-color">
                        <Carousel.Caption className={isPreview ? "preview" : ""}>
                            {!isPreview
                                ? detail_page_id
                                    ? <Link to={`/${detail_page_id}`}>
                                        <h6>{t("term.news")}</h6>
                                        <h1>{title}</h1>
                                        <p>{published_date}</p>
                                    </Link>
                                    : <a target="_blank" rel="noopener noreferrer" href={url}>
                                        <h6>{t("term.news")}</h6>
                                        <h1>{title}</h1>
                                        <p>{published_date}</p>
                                    </a>
                                : <div>
                                    <h6>{t("term.news")}</h6>
                                    <h1>{title}</h1>
                                    <p>{published_date}</p>
                                </div>
                            }
                        </Carousel.Caption>
                    </div>

                </Carousel.Item>
            ))}
        </Carousel>
    </React.Fragment>
}

/**
 * Description - Component to show last 5 news in landing page
 * @constructor
 */
const LandingPageNewsSection: React.FC = () => {
    const {data, isLoading, error} = useGetLastNews()
    return <ComponentSwitch loading={isLoading} loadingComponent={<PageLoading/>} isPage error={error}>
        {data !== undefined && !isLoading && <LandingPageNewsSectionView data={data}/>}
    </ComponentSwitch>
}

export default LandingPageNewsSection