import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import nl from "./resources/nl.json"
import fr from "./resources/fr.json"
import en from "./resources/en.json"
import de from "./resources/de.json"
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    fr: {
        translation: fr
    },
    nl: {
        translation: nl
    },
    en: {
        translation: en
    },
    de: {
        translation: de
    },

}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        detection: {
            order: ["localStorage", "navigator"]
        },
        load: "languageOnly",
        keySeparator: ".",
        fallbackLng: () => "nl",
        interpolation: {
            escapeValue: false
        }
    })

export default i18n