import React, {useCallback, useMemo, useState} from "react";
import {FooterModelView} from "./models/FooterModelView";
import {Container} from "react-bootstrap";
import {useGetFooter} from "./FooterData";
import ComponentSwitch from "../ui/component-switch/ComponentSwitch";
import MainMenuLoading from "../navigation/main-menu/MainMenuLoading";
import {useSwitch} from "../ui/collapse/panel-expandable/PanelExpandable";
import GDPRDialog from "../gdpr/GDPRDialog";
import {useTranslation} from "react-i18next";
import PageDialog from "../dialog/PageDialog";

/**
 * Description - test ids of footer component to catch subcomponent for testing
 */
export const testIds = {
    acknowledgement: "footer-project-acknowledgement",
    socialMedia: "footer-social-media",
    founder: "footer-founder",
    copyright: "footer-copyright",
    copyrightNotices: "footer-copyright-notices",
    privacyAndTerms: "footer-privacy-and-terms",
    cookiePreferences: "footer-cookies-preferences"
}

const getPageIdFromPath = (path: string) => {
    const slugs = path.split("/")
    return slugs[slugs.length - 1]
}

/**
 * Description - View Component to render Footer view
 *  - display project and copyright information
 *  - render gdpr dialog
 * @param data
 * @constructor
 */
export const FooterView: React.FC<{data: FooterModelView}> = ({data}) => {

    const {t} = useTranslation()

    const {copyright, privacyAndTerms, cookiePreferences} = data
    const textCopyright = useMemo<string>(() =>{
        const currentYear = new Date().getFullYear().toString()
        return copyright && new Date(copyright.dateBegin).getFullYear().toString() !== currentYear
            ? `${t("footer.copyright")} ${new Date(copyright.dateBegin).getFullYear()} - ${currentYear}`
            : `${t("footer.copyright")} ${currentYear}`
    },[copyright, t])

    const {onOpen: onOpenPage, onClose: onClosePage, open: openPage} = useSwitch()
    const {onOpen, onClose, open} = useSwitch()

    const [selectedPage, setSelectedPage] = useState<string | undefined>()

    const onSelectPage = useCallback((pageIdSelected?: string) => {
       if(pageIdSelected !== undefined){
           onOpenPage()
           setSelectedPage(pageIdSelected)
       }
    }, [onOpenPage])

    return (
        <footer>
            <Container>
                <p>{textCopyright} {copyright?.notice}</p>
                <div className="d-flex gap-4 justify-content-center">
                    {privacyAndTerms && <span className={"dialog-link"} onClick={() => onSelectPage(getPageIdFromPath(privacyAndTerms?.path))} >{privacyAndTerms?.label} </span>}
                    {cookiePreferences && <span className={"dialog-link"} onClick={() => onSelectPage(getPageIdFromPath(cookiePreferences?.path))} >{cookiePreferences?.label} </span>}
                    <span className={"dialog-link"} onClick={onOpen} >{t("footer.data-protection-link-label")}</span>
                </div>
            </Container>
            <GDPRDialog open={open} onClose={onClose}/>
            <PageDialog onClose={onClosePage} open={openPage} pageId={selectedPage}/>
        </footer>
    )
}

/**
 * Description - Footer component
 * @constructor
 */
const Footer:React.FC = () => {
    const {data, isLoading, error} = useGetFooter()
    return <ComponentSwitch loading={isLoading} loadingComponent={<MainMenuLoading/>} error={error}>
        {data !== undefined && <FooterView data={data} />}
    </ComponentSwitch>
}

export default Footer