import {MainMenuModelData, menuModelDataSchema, SubMenuItemModelData} from "./models/MainMenuModelData";
import {MenuItemModelView, MainMenuModelView} from "./models/MainMenuModelView";
import {mapper} from "../../../utils/mapper/mapper";
import {convertApiObjectDataToValue} from "../../../data-access/api-call/wagtail-rest-page-api";
import {useApiQuery} from "../../../data-access/data-caching";
import {useContext, useMemo} from "react";
import ApiCallContext from "../../../data-access/api-call/ApiCallContext";
import {GetPagesData} from "../../../data-access/api-call/wagtail-rest-page-api/page";
import {ErrorApplicationData} from "../../../models/error/ErrorModel";

export const MAIN_MENU_WAGTAIL_MODEL_NAME = "menu.MenuPage"
export const MAIN_MENU_QUERY_NAME = "get_menu"
export const MAIN_MENU_MODEL_NAME = "Main menu"

/**
 * Description - Method to convert main-menu model data to model view
 * @param menuData
 */
export const convertModelDataToMenuModelView = (menuData: MainMenuModelData): MainMenuModelView => {
    return menuData.submenu.map(menuItem => {
        return mapper<MenuItemModelView, MainMenuModelView>({...convertApiObjectDataToValue(menuItem)},
            {
                "label": "title",
                "children": {
                    key: "item",
                    serializer: (items: any): MenuItemModelView => {
                        return items.length > 1
                            ? items.map((subItem: SubMenuItemModelData) => ({
                                label: subItem.label,
                                link: subItem.link.toString()
                            }))
                            : undefined
                    }
                },
                "link": {
                    key: "item",
                    serializer: (items: any): MenuItemModelView => {
                        return items.length === 1
                            ? items[0].link.toString()
                            : undefined
                    }
                }

            })
    })
}

/**
 * Description - Service to get main-menu data from wagtail api rest
 */
export const useGetMenu = ():{data: MainMenuModelView | undefined, isLoading: boolean, error?: ErrorApplicationData} => {
    const {getPages} = useContext(ApiCallContext)
    const {data, isLoading, error} = useApiQuery<GetPagesData<MainMenuModelData>>(MAIN_MENU_QUERY_NAME, getPages, {
            variables: {
                fields: "*",
                type: MAIN_MENU_WAGTAIL_MODEL_NAME
            },
            schemaValidation: menuModelDataSchema,
            model: MAIN_MENU_MODEL_NAME
        },
    )

    const menuView = useMemo(() => {
        return data && data.items.length && !isLoading ? convertModelDataToMenuModelView(data.items[0]) : undefined
    }, [data, isLoading])

    return {data: menuView, isLoading, error}
}

