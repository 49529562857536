import React from "react";
import {GetPagesVariables} from "./wagtail-rest-page-api/page";
import {ApiCallType} from "./ApiCall";
import {RegistrationFormData} from "../../components/registration/models/RegistrationModelData";

/**
 * Description - React Context implementation to inject all process
 */
const ApiCallContext = React.createContext<ApiCallType>({
    getPages: (_variables: GetPagesVariables) => Promise.resolve(undefined),
    getPage: (_pageId: string) => Promise.resolve(undefined),
    getTranslationId: (_pageId: string) => Promise.resolve(""),
    getImage: (_id: string) => Promise.resolve(undefined),
    getNews: (_variables: GetPagesVariables) => Promise.resolve(undefined),
    getThemes: () => Promise.resolve(undefined),
    getActor: (_id: string) => Promise.resolve(undefined),
    onSubscribe: (_registration: RegistrationFormData) => Promise.resolve(undefined),
    getCaptcha: () => Promise.resolve(undefined),
    verifyTranslationContent: () => Promise.resolve([])
})

export const ApiCallProvider = ApiCallContext.Provider

export default ApiCallContext