import React, {ReactElement, ReactNode, useCallback, useState} from "react";
import {DefaultProviderModel} from "../../../../utils/provider/DefaultProviderModel";
import {Stack} from "react-bootstrap";
import DefaultCollapse from "../default-collapse/DefaultCollapse";

/**
 * Description - Service to manage open value
 * @param props
 */
export const useSwitch = (props?: {defaultValue?: boolean}) => {
    const [open, setOpen] = useState<boolean>(!!props?.defaultValue)

    const onSwitch = useCallback(() => {
        setOpen((currentValue) => !currentValue)
    },[])

    const onOpen = useCallback(() => {
        setOpen(true)
    },[])

    const onClose = useCallback(() => {
        setOpen(false)
    }, [])

    return {onClose, onOpen, onSwitch, open}
}

export interface PanelExpandableHeaderProps extends DefaultProviderModel {
    onSwitch: () => void;
    open: boolean;
    id: string;
    className?: string;
    childrenNotExpand?: boolean
}

/**
 * Description - UI Component to render panel expandable header with action button to open or close body panel
 * Remark: all html element from children must be have button as role value to expand collapse in case if childrenNotExpand props is true
 * @param onSwitch
 * @param childrenNotExpand
 * @param open
 * @param children
 * @param id
 * @param className
 * @constructor
 */
export const PanelExpandableHeader: React.FC<PanelExpandableHeaderProps> = ({onSwitch, childrenNotExpand, open, children, id, className}) => {

    const onClick: React.MouseEventHandler<HTMLElement> = useCallback((e) => {
        // @ts-ignore
        if(e.target.role === "button" && childrenNotExpand) {
            onSwitch()
        }
        if(!childrenNotExpand){
            onSwitch()
        }
    }, [onSwitch, childrenNotExpand])
    return  <Stack onClick={onClick} role="expand" className={`panel-expandable-header${className ? ` ${className}` : ""}`} direction="horizontal" gap={2}>
        {children}
        <div
            role="button"
            className="ms-auto expand-button"
            aria-controls={id}
            aria-expanded={open}
        >
            {open
                ? <i role="button" className="bi bi-chevron-down"></i>
                : <i role="button" className="bi bi-chevron-right"></i>
            }
        </div>
    </Stack>
}

export interface PanelExpandableBodyProps extends DefaultProviderModel {
    open: boolean;
    id: string;
    className?: string;
}

/**
 * Description - UI Component to render panel expandable body, it is collapse part to show or not
 * @param open
 * @param children
 * @param id
 * @param className
 * @constructor
 */
export const PanelExpandableBody: React.FC<PanelExpandableBodyProps> = ({open, children, id, className}) => {
    return <div className={`panel-expandable-body${className ? ` ${className}` : ""}`}>
        <DefaultCollapse open={open} id={id}>
            {children}
        </DefaultCollapse>
    </div>
}

export interface PanelExpandableProps{
    header: ReactNode | ReactElement | null;
    body: ReactNode | ReactElement | null;
    id: string;
    desktopNotExpandable?: boolean
    childrenNotExpand?: boolean
}

/**
 * Description - Panel expandable component, he is composed two part:
 * - header with title and button action to expand
 * - body with content expandable
 * @param header
 * @param id
 * @param body
 * @param desktopNotExpandable
 * @param childrenNotExpand
 * @constructor
 */
const PanelExpandable: React.FC<PanelExpandableProps> = ({header, id, body, desktopNotExpandable, childrenNotExpand}) => {
    const {open, onSwitch} = useSwitch()
    return <div>
        <PanelExpandableHeader className={desktopNotExpandable ? "desktop-not-expandable" : ""} onSwitch={onSwitch} open={open} id={id} childrenNotExpand={childrenNotExpand}>
            {header}
        </PanelExpandableHeader>
        <PanelExpandableBody className={desktopNotExpandable ? "desktop-not-expandable" : ""} open={open} id={id}>
            {body}
        </PanelExpandableBody>
    </div>
}

export default PanelExpandable;