import {ServiceModelView} from "../../service/models/ServiceModelView";
import React, {useMemo} from "react";
import {ThemePageModelView} from "../../page/page-model/theme-page/models/ThemePageModelView";
import {ThemePageView} from "../../page/page-model/theme-page/ThemePage";
import {LinkApplication} from "../../../models/general_model";

export interface ServiceTemplatePreviewProps {
    data: ServiceModelView
}

export const themePagePreviewForService: ThemePageModelView = {
    id: "28",
    title: "Service view",
    description: "<p>Mary had to make a decision and she knew that whatever decision she made, it would upset someone. It seemed like such a silly reason for people to get upset but she knew the minute that she began to consider doing it that there was no way everyone in her life would be pleased with what she ultimately decided to do. It was simply a question of who she would rather displease most. While this had always been her parents, and especially her mom, in the past that she tried to keep from upsetting, she decided that this time the person she was going to please the most with her decision was herself.</p>" +
        "<p>There was a reason for her shyness. Everyone assumed it had always been there but she knew better. She knew the exact moment that the shyness began. It had been that fateful moment at the lake. There are just some events that do that to you.</p>",
    image: {
        imageUrl: `${process.env.REACT_APP_API_DOMAIN}`,
        altText: "Theme preview image"
    },
    services: []
}

/**
 * Description - Variable to replace actorLink for ServiceTemplatePreview
 * It is not possible to get actor link from api when we get a service directly
 */
export const linkForPreview: Array<LinkApplication> = [
    {
        "url": "https://thinktanknorthsea.be/nl/home",
        "label": "The opened package of potato chips held the answer to the mystery. Both detectives looked at it but failed to realize it was the key to solve the crime. They passed by it assuming it was random trash ensuring that the case would never be solved."
    },
    {
        "url": "https://thinktanknorthsea.be/nl/reports",
        "label": "The opened package of potato chips held the answer to the mystery. Both detectives looked at it but failed to realize it was the key to solve the crime. They passed by it assuming it was random trash ensuring that the case would never be solved."
    }
]

/**
 * Description - Variable to replace theme pages info for ServiceTemplatePreview
 * It is not possible to get theme page info from api when we get a service directly
 */
export const themePagesInfoForPreview:  Array<{
    theme_page_id: string;
    name: string;
}> = [
    {
        "theme_page_id": "27",
        "name": "Economie Bleu"
    },
]

/**
 * Description - UI Component to render services for preview
 * @param data
 * @constructor
 */
const ServiceTemplatePreview: React.FC<ServiceTemplatePreviewProps> = ({data}) => {
    const themePagePreview = useMemo(() => {
        return {...themePagePreviewForService, services: [data]}
    }, [data])

    return <ThemePageView data={themePagePreview}/>
}

export default ServiceTemplatePreview