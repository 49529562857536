import {LandingPageModelView} from "./models/LandingPageModelView";
import React, {useMemo} from "react";
import {useGetLandingPage} from "./LandingPageData";
import LandingPageActorsSection from "./ui/landing-page-actors-section/LandingPageActorsSection";
import LandingPageCard from "./ui/landing-page-card/LandingPageCard";
import ComponentSwitch from "../../../ui/component-switch/ComponentSwitch";
import PageLoading from "../../PageLoading";
import LandingPageNewsSection from "./ui/landing-page-news-section/LandingPageNewsSection";
import RichText from "../../../ui/RichText/RichText";
import {Container, Image} from "react-bootstrap";

export const testIds = {
    content: "landing-page-content"
}

/**
 * Description - View Component to render different part of landing page
 * @param data
 * @constructor
 */
export const LandingPageView: React.FC<{data: LandingPageModelView}> = ({data}) => {
    const {content, actor, galleryImages} = useMemo(() => data, [data])
    return (
        <div>
            <LandingPageNewsSection/>
            <Container>
                <RichText className="margin-top-2" content={content}/>

                {
                    // We used the first image from the gallery as minister image
                    galleryImages.length > 0 && <Image width="100%" src={galleryImages[0].imageUrl} alt="minister_portrait" />
                }
            </Container>
            <LandingPageCard/>
            <LandingPageActorsSection data={actor}/>
        </div>
    )
}

/**
 * Description - Landing Page component
 * - implement service to fetch landing page data from wagtail rest api
 *  - rendering in loading state
 *  - rendering landing page view component
 * @constructor
 */
const LandingPage: React.FC = () => {

    const {data, isLoading, error} = useGetLandingPage()

    return <ComponentSwitch isPage loading={isLoading} loadingComponent={<PageLoading/>} error={error}>
        {data !== undefined && <LandingPageView data={data}/>}
    </ComponentSwitch>
}

export default LandingPage