import React, {ReactNode, useContext, useMemo} from "react";
import ApiCallContext from "../../ApiCallContext";
import {useApiQuery} from "../../../data-caching";
import {GetPagesData} from "../page";
import {ErrorApplicationData} from "../../../../models/error/ErrorModel";
import ComponentSwitch from "../../../../components/ui/component-switch/ComponentSwitch";
import PageLoading from "../../../../components/page/PageLoading";
import {Container} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {DEFAULT_SEO_DESCRIPTION, DEFAULT_SEO_TITLE} from "../../../../seo";

export const testIds = {
    siteFallbackComponent: "site-fallback-component"
}

export const GET_SITE_ID_QUERY_NAME = "get_site_id"

/**
 * Description - Service to get site id by site name fron wagtail rest api
 */
export const useGetSiteId = ():{data: SiteContextProps | undefined, isLoading: boolean, error: ErrorApplicationData | undefined} => {
    const {getPages} = useContext(ApiCallContext)

    if(!process.env.REACT_APP_SITE_NAME){
        throw Error("SITE NAME variable is missing")
    }

    const {data, isLoading, error} = useApiQuery<GetPagesData>(GET_SITE_ID_QUERY_NAME, getPages, {variables:{slug: process.env.REACT_APP_SITE_NAME, fields: "*"}})

    const siteData: SiteContextProps | undefined = useMemo(() => {
        if(data && data.items.length && data.items[0].id){
            const sitePage = data.items[0]
            return {siteId: sitePage.id, seoMeta: {title: sitePage.meta.seo_title ? sitePage.meta.seo_title : DEFAULT_SEO_TITLE, description: sitePage.meta.search_description ? sitePage.meta.search_description : DEFAULT_SEO_DESCRIPTION}}
        } else {
            return undefined
        }
    },[data])

    return {data: siteData, isLoading, error}
}

export interface SiteContextProps {
    siteId: number;
    seoMeta: {
        title: string;
        description: string
    }
}

export const SiteContext = React.createContext<SiteContextProps>(({
    siteId: -1,
    seoMeta: {
        title: "",
        description: ""
    }
}))

export const SiteContextProvider = SiteContext.Provider

export const SiteErrorComponent: React.FC = () => {
    const {t} = useTranslation()
    return <Container data-testid={testIds.siteFallbackComponent} className="page-empty">
            <div className="ErrorUi">
                <i className="bi bi-cone text-warning">
                    {t("error.messageApiAccess")}
                </i>
            </div>
        </Container>
}


export const SiteProvider: React.FC<{children: ReactNode}> = ({children}) => {
    const {data, isLoading, error} = useGetSiteId()

    return <ComponentSwitch loadingComponent={<PageLoading/>} loading={isLoading} error={error} errorComponent={<SiteErrorComponent/>} >
        {
            !isLoading && data !== undefined && <SiteContextProvider value={data}>
                {children}
            </SiteContextProvider>
        }
    </ComponentSwitch>
}