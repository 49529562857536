import React, {useMemo} from "react";
import {Button, Modal, Spinner} from "react-bootstrap";
import {useGetPage} from "../page/PageData";
import ComponentSwitch from "../ui/component-switch/ComponentSwitch";
import {usePageDispatcher} from "../page/page-dispatching/PageDispatcher";
import {pageDispatchingPublic} from "../page/page-dispatching/PageDispatching";
import {useTranslation} from "react-i18next";
import {BASIC_PAGE_WAGTAIL_MODEL_NAME} from "../page/page-model/basic-page/BasicPageData";
import RichText from "../ui/RichText/RichText";
import {BasicPageModel} from "../page/page-model/basic-page/model/BasicPageModel";

export interface PageDialogProps {
    pageId?: string,
    onClose: () => void
    open: boolean
}

/**
 * Description - Component to render page content by id page in dialog component, he get template by data type from data
 * Remark - In case if data type is basic page, the component will inject title as modal title and the content is rendering inside rich text component inside the modal body
 * @param pageId
 * @param onClose
 * @param open
 * @constructor
 */
const PageDialog:React.FC<PageDialogProps> = ({pageId, onClose, open}) => {

    const {data, isLoading, error: getPageError} = useGetPage(pageId)
    const {component, error: componentError} = usePageDispatcher({data, pageDispatchingList: pageDispatchingPublic})

    const error = useMemo(() => {
        if(getPageError){
            console.error(getPageError)
            return getPageError
        }
        if(componentError){
            console.error(componentError)
            return componentError
        }
        return undefined
    }, [getPageError, componentError])

    // Content is case if data is basic page content
    const contentAsDataBasicPageContent = useMemo(() => {
        if(data !== undefined && (data as BasicPageModel).content){
            return (data as BasicPageModel).content
        } else {
            return undefined
        }
    }, [data])

    const {t} = useTranslation()
    return <Modal size="lg" show={open} onHide={onClose}>
        <ComponentSwitch error={error} loading={isLoading} loadingComponent={<Spinner animation="border" />}>
            {data !== undefined && <React.Fragment>
              <Modal.Header closeButton={true}>
                  {data.meta.type === BASIC_PAGE_WAGTAIL_MODEL_NAME && <Modal.Title>{data.title}</Modal.Title>}
              </Modal.Header>
              <Modal.Body>
                  {data.meta.type === BASIC_PAGE_WAGTAIL_MODEL_NAME && contentAsDataBasicPageContent !== undefined
                      ? <RichText content={contentAsDataBasicPageContent} />
                      : component
                  }
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={onClose}>
                    {t("action.close")}
                </Button>
              </Modal.Footer>
            </React.Fragment>}
        </ComponentSwitch>
    </Modal>
}

export default PageDialog