import {PageDispatchingModel} from "../../page/page-dispatching/models/PageDispatchingModel";
import {GDPRModel, gDPRModelDataSchema} from "../models/GDPRModel";
import {GDPR_WAGTAIL_MODEL_NAME} from "../GDPRData";
import GDPRTemplatePreview from "../../preview/gdpr-template-preview/GDPRTemplatePreview";

export const gDPRDispatchingForPreview: PageDispatchingModel<GDPRModel, GDPRModel> = {
    wagtail_model_name: GDPR_WAGTAIL_MODEL_NAME,
    serializer: (data) => data,
    component: (view) => <GDPRTemplatePreview data={view}/>,
    schemaValidation: gDPRModelDataSchema
}