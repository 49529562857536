import {GetPagesItemData} from "../../../../../data-access/api-call/wagtail-rest-page-api/page";
import {
    ApiImageData,
    apiImageDataSchema
} from "../../../../../data-access/api-call/wagtail-rest-page-api/image/models/ImageModel";
import {ServiceModelData, serviceModelDataSchema} from "../../../../service/models/ServiceModelData";
import * as yup from "yup"
import {requiredMessage} from "../../../../../models/error/ModelErrorValidation";
import {ObjectSchema} from "yup";
export interface ThemePageServiceModelData  {
    service_page: ServiceModelData
}


export const themePageServiceModelData = yup.object({
    // @ts-ignore
    service_page: serviceModelDataSchema.required(requiredMessage)
})

export interface ThemePageModelData extends GetPagesItemData {
    description: string;
    icon: ApiImageData;
    theme_service: Array<ThemePageServiceModelData>
}

// @ts-ignore
export const themePageModelDataSchema: ObjectSchema<any> = yup.object({
    id: yup.number().required(),
    title: yup.string().required(requiredMessage),
    description: yup.string().required(requiredMessage),
    icon: apiImageDataSchema.required(requiredMessage),
    theme_service: yup.array(themePageServiceModelData).required(requiredMessage).nullable()
})

// @ts-ignore
export const themePageLightModelDataSchema: ObjectSchema<any> = yup.object({
    title: yup.string().required(requiredMessage),
    description: yup.string().required(requiredMessage),
    icon: apiImageDataSchema.required(requiredMessage),
})