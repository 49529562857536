import React, {useContext, useState} from "react";
import {DefaultProviderModel} from "../../../utils/provider/DefaultProviderModel";

export interface NavigationContextType {
    pageSelected: number | "";
    setPageSelected: (pageSelected: number | "") => void;
}

const NavigationService = React.createContext<NavigationContextType>({
    pageSelected: "",
    setPageSelected: (_pageSelected) => {}
})

/**
 * Description - Provider to inject navigation service in application
 *  - Creation service to manage page selected state
 *  - inject service in application
 * Remark - It is a React context implementation
 * @param children
 * @constructor
 */
export const NavigationServiceProvider: React.FC<DefaultProviderModel> = ({children}) => {
    const [pageSelected, setPageSelected] = useState<number | "">("")

    return <NavigationService.Provider
        value={{
            pageSelected,
            setPageSelected
        }}
    >
        {children}
    </NavigationService.Provider>
}

/**
 * Description - Hook to get navigation service
 * Remark - It is a React context implementation
 */
export const useGetNavigationService = () => {
    return useContext(NavigationService)
}