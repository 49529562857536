import React, {createRef, useMemo} from "react";
import {useLocation, useRoutes} from "react-router-dom";
import {routeNode} from "../../routing";
import FadeTransition from "../ui/transition/FadeTransition";
import {useScrollTopNavigation} from "../../routing/routes";

/**
 * Description - UI Component to render site content
 *  - manage page fade transition
 *  - render page by route
 * @constructor
 */
const Content: React.FC = () => {

    useScrollTopNavigation()

    const location = useLocation()
    const nodeRef = useMemo(() => {
        if(location.key){
            return createRef()
        }
    },[location])
    return <FadeTransition
        activeKey={location.key}
        nodeRef={nodeRef}
    >
        {useRoutes(routeNode, location)}
    </FadeTransition>
}

export default Content