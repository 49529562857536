import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import {Button, Container} from "react-bootstrap";
import {LinkApplication} from "../../../models/general_model";

export interface  MessagePanelProps {
    message: string;
    link?: LinkApplication;
    variant?: "success" | "warning" | "danger" | "primary" | "secondary"
}

/**
 * Description - UI Component to render message in panel
 * @param message
 * @param link
 * @param variant
 * @constructor
 */
const MessagePanel: React.FC<MessagePanelProps> = ({message, link, variant}) => {
    const colorVariant = useMemo(() => variant ? variant : "primary", [variant])
    return <Container className={`panel ${colorVariant}-panel padding-top-2 padding-left-2 padding-right-2 padding-bottom-2 margin-bottom-1`}>
        <h4>{message}</h4>
        {link !== undefined && <Link to={link.url}><Button className="margin-top-2" variant={colorVariant}>{link.label}</Button></Link> }
    </Container>
}

export default MessagePanel