import {NewsModelDetailView} from "../../../news/models/NewsModelView";
import RichText from "../../../ui/RichText/RichText";
import {Container} from "react-bootstrap";
import SpacingMenu from "../../../ui/spacing/SpacingMenu";
import React from "react";
export interface NewsDetailPageProps {
    data: NewsModelDetailView
}

export const NewsDetailPageView: React.FC<NewsDetailPageProps> = ({data}) => {
    return <SpacingMenu>
        <Container className="margin-bottom-1 text-start">
            <h1 className="margin-bottom-2 ">{data.title}</h1>
            <RichText content={data.content}/>
        </Container>
    </SpacingMenu>
}
