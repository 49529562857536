import {Form, Stack} from "react-bootstrap";
import React, {useCallback, useEffect, useMemo} from "react";
import {SelectOption} from "../models";
import {useTranslation} from "react-i18next";

export const selectAllValue = "select-all"
export const deselectAllValue = "deselect-all"

export interface MultiSelectServiceVariables {
    options: Array<SelectOption>;
    value: Array<number>;
    onChange: (newValue: Array<number>) => void;
    allSelectedByDefault? : boolean;
}

export interface MultiSelectProps {
    options: Array<SelectOption>
    value: Array<number>;
    onChange: (newValue: Array<number>) => void;
    label: string;
    placeholder?: string;
    allSelectedByDefault? : boolean;
    required?: boolean;
    errorMessage?: string;
}

export const labelKeys = {
    allSelectOption: "ui.form.multiselect.allSelectOptionLabel",
    allDeselectOption: "ui.form.multiselect.allDeselectOptionLabel"
}

/**
 * Description - Service to manage multiselect form input
 * @param options
 * @param value
 * @param onChange
 * @param allSelectedByDefault
 */
export const useMultiSelectService = ({options, value, onChange, allSelectedByDefault}: MultiSelectServiceVariables) => {

    const optionsDisplayed: Array<SelectOption> = useMemo(() => {
        return options.filter(({value: optionValue}) => {
            return !value.includes(optionValue)
        })
    },[options, value])

    const displayValue: Array<SelectOption> = useMemo(() => {return options.filter(({value: optionValue, label}) => {
            return value.includes(optionValue)
        })
    }, [options, value] )

    const handleChange = useCallback((e: any) => {
        if(e.target.value === selectAllValue) {
            onChange(options.map(({value: optionValue}) => optionValue))
        } else if(e.target.value === deselectAllValue) {
            onChange([])
        } else if(!value.includes(e.target.value)){
            onChange([...value, parseInt(e.target.value)])
        }
    }, [onChange, value, options])

    // selected all by default
    useEffect(() => {
        if(allSelectedByDefault){
            onChange([...options.map(({value}) => value)])
        }
    },[onChange, options, allSelectedByDefault])

    const deselectOption = useCallback((valueSelected: number) => {
        onChange(value.filter(itemValue => itemValue !== valueSelected))
    }, [onChange, value])

    return {optionsDisplayed, handleChange, displayValue, deselectOption}
}

/**
 * Description - UI Component to manage and rendering multiselect
 * @param options
 * @param value
 * @param onChange
 * @param label
 * @param placeholder
 * @param allSelectedByDefault
 * @param required
 * @param errorMessage
 * @constructor
 */
const MultiSelect: React.FC<MultiSelectProps> = ({options, value, onChange, label, placeholder, allSelectedByDefault, required, errorMessage}) => {

    const {t} = useTranslation()

    const {displayValue, optionsDisplayed, handleChange, deselectOption} = useMultiSelectService({options, value, onChange, allSelectedByDefault})

    return <div className="multiselect">
        <Form.Group className="form-group" controlId="theme" >
            <Form.Label>{label}</Form.Label> {required && <span>{" *"}</span>}
          <div className="form-control-container">
              <Stack direction="horizontal" className="multiselect-chips-container stack-responsive">
                  {displayValue.map(({label, value}) => <Stack role="insertion" gap={2} direction="horizontal" key={value} className="multiselect-chips">
                      <div>{label}</div>
                      <div className="ms-auto"><i role="button" onClick={() => deselectOption(value)} className="bi bi-x"/>
                      </div>
                  </Stack>)}
              </Stack>
            <Form.Control isInvalid={errorMessage !== undefined} className="form-select" value="" type="select" as="select" onChange={handleChange}
                          // @ts-ignore
                          placeholder={placeholder}>
              <option className="placeholder" hidden value="">{placeholder}</option>
                {options.length !== value.length && <option value={selectAllValue} >{t(labelKeys.allSelectOption)}</option>}
                {value.length > 0 && <option value={deselectAllValue} >{t(labelKeys.allDeselectOption)}</option>}
                {optionsDisplayed.map(({value: optionValue, label}) => (
                    <option key={optionValue} value={optionValue} >{label}</option>
                ))}
            </Form.Control>
              {errorMessage !== undefined && <Form.Text className="text-danger">{errorMessage}</Form.Text>}
          </div>

        </Form.Group>
    </div>
}

export default MultiSelect