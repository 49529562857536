import {PageDispatchingModel} from "./models/PageDispatchingModel";
import {defaultThemePageDispatching} from "../page-model/theme-page/theme-page-dispatching/ThemePageDispatching";
import {
    defaultNewsIndexPageDispatcher,
    newsPageDispatchingForPreview
} from "../page-model/news-page/news-page-dispatcher/NewsPageDispatcher";
import {
    defaultRegistrationPageDispatcher
} from "../page-model/registration-page/registation-page-dispatcher/RegistrationPageDispatcher";
import {defaultLandingPageDispatcher} from "../page-model/landingPage/landing-page-dispatcher/LandingPageDispatcher";
import {footerDispatcherForPreview} from "../../footer/footer-dispatcher/FooterDispatcher";
import {headerDispatcherForPreview} from "../../header/header-dispatcher/HeaderDispatcher";
import {mainMenuDispatcherForPreview} from "../../navigation/main-menu/main-menu-dispatcher/MainMenuDispatcher";
import {gDPRDispatchingForPreview} from "../../gdpr/gdpr-dispatching/GDPRDispatching";
import {
    serviceDispatchingForPreview,
    serviceIndexPageDispatchingForPreview
} from "../../service/service-dispatching/ServiceDispatching";
import {sitePageDispatchingForPreview} from "../page-model/site/site-dispatching/SiteDispatching";
import { defaultBasicPageDispatcher } from "../page-model/basic-page/basic-page-dispatcher/BasicPageDispacher";
import {
    defaultNewsDetailPageDispatcher,
    newsDetailPageDispatcherForPreview
} from "../page-model/news-detail-page/news-detail-page-dispatcher/NewsDetailPageDispatcher";

export const pageDispatchingPublic: Array<PageDispatchingModel> = [
    // @ts-ignore
    defaultLandingPageDispatcher,
    // @ts-ignore
    defaultRegistrationPageDispatcher,
    // @ts-ignore
    defaultThemePageDispatching,
    defaultNewsIndexPageDispatcher,
    // @ts-ignore
    defaultBasicPageDispatcher,
    // @ts-ignore
    defaultNewsDetailPageDispatcher
]

export const pageDispatchingPreview: Array<PageDispatchingModel> = [
    // @ts-ignore
    defaultLandingPageDispatcher,
    // @ts-ignore
    defaultRegistrationPageDispatcher,
    // @ts-ignore
    defaultThemePageDispatching,
    defaultNewsIndexPageDispatcher,
    // @ts-ignore
    
    footerDispatcherForPreview,
     // @ts-ignore
    defaultBasicPageDispatcher,
    // @ts-ignore
    headerDispatcherForPreview,
    // @ts-ignore
    mainMenuDispatcherForPreview,
    // @ts-ignore
    gDPRDispatchingForPreview,
    // @ts-ignore
    newsPageDispatchingForPreview,
    // @ts-ignore
    serviceDispatchingForPreview,
    serviceIndexPageDispatchingForPreview,
    sitePageDispatchingForPreview,
    // @ts-ignore
    newsDetailPageDispatcherForPreview
]