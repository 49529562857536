import React from "react";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import {DefaultProviderModel} from "../../../utils/provider/DefaultProviderModel";

export const DEFAULT_FADE_CLASSNAMES = "fade";
export const DEFAULT_FADE_TIMEOUT = 300;

export interface FadeTransitionProps extends DefaultProviderModel{
    activeKey: any;
    nodeRef?: React.MutableRefObject<any>
}

const FadeTransition: React.FC<FadeTransitionProps> = ({children, activeKey, nodeRef}) => {
    return (
        <SwitchTransition>
            <CSSTransition
                key={activeKey}
                nodeRef={nodeRef}
                classNames={DEFAULT_FADE_CLASSNAMES}
                timeout={DEFAULT_FADE_TIMEOUT}
            >
                <div ref={nodeRef}>
                    {children}
                </div>
            </CSSTransition>
        </SwitchTransition>
    )
}

export default FadeTransition;