import {FieldErrors, UseFormSetValue, UseFormWatch} from "react-hook-form";
import {
    RegistrationErrorsModelData,
    RegistrationFormData
} from "../../../../../../registration/models/RegistrationModelData";
import {useCallback, useMemo} from "react";

/**
 * Description - Service to manage theme form field value mutation, it is implementation of react hook form as form state and validation manager
 * @param setValue
 * @param watch
 */
export const useThemeValueFormMutation = ({setValue, watch}: { setValue: UseFormSetValue<RegistrationFormData>, watch: UseFormWatch<RegistrationFormData> }) => {
    const value = watch("themes")
    const handleChange = useCallback((newValue: Array<number>) => {
        setValue("themes", newValue)
    }, [setValue])
    return {value, handleChange}
}
/**
 * Description - Service to manage captcha form field value mutation, it is implementation of react hook form as form state and validation manager
 * @param setValue
 * @param errors
 * @param errorsBackend
 */
export const useCaptchaValueFormMutation = ({setValue, errors, errorsBackend}: { setValue: UseFormSetValue<RegistrationFormData>, errorsBackend: RegistrationErrorsModelData | null, errors: FieldErrors<RegistrationFormData> }) => {
    const handleKey = useCallback((key: string) => {
        setValue("captcha_hashkey", key)
    }, [setValue])

    const errorFormValidation: { key?: string, value?: string } | undefined = useMemo(() => {
        return {
            key: errors.captcha_hashkey?.message ? errors.captcha_hashkey.message : undefined,
            value: errors.captcha_code?.message ? errors.captcha_code.message : undefined
        }
    }, [errors])

    const haveErrorBackend: boolean | undefined = useMemo(() => {
        return errorsBackend?.captcha?.length !== undefined;
    }, [errorsBackend])

    return {handleKey, errorFormValidation, haveErrorBackend}
}