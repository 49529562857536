import {BrowserRouter} from "react-router-dom";
import React from "react";

if(!process.env.REACT_APP_BASENAME) {
    throw Error("the BASENAME variable is missing")
}

export interface RouterProps{
    children: React.ReactNode
}

/**
    Description - Router of application
 */
const Router: React.FC<RouterProps> = ({children}) => {
    return (
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>{children}</BrowserRouter>
    )
}

export default Router