import {ErrorApplicationData} from "../../models/error/ErrorModel";
import React, {Fragment, ReactElement, ReactNode, useMemo} from "react";
import SpacingMenu from "../ui/spacing/SpacingMenu";
import {Container} from "react-bootstrap";
import ErrorContent from "../ui/error/ErrorContent";
import ErrorRunning from "../ui/error/ErrorRunning";
import ErrorApiProblem from "../ui/error/ErrorApiProblem";


export interface ErrorBounderiesProps {
    error?: ErrorApplicationData,
    children: ReactNode,
    fallbackComponent?: ReactNode
}

export const ERROR_COMPONENT_TEST_ID = {
    running: "error-component-running",
    api: "error-component-api",
    content: "error-component-content",
}

/**
 * Description - Method to convert error type to message for user
 * @param error
 */
export const convertErrorTypeToMessageView = (error: ErrorApplicationData | undefined): string | undefined | ReactElement=> {
    if(error?.type === "run" || error?.type === "validation" || error?.type === "api-no-access" || error?.type === "api-bad-request"){
        return  <div data-testid={ERROR_COMPONENT_TEST_ID.running} ><ErrorRunning error={error} /></div>
    } else if(error?.type === "api-problem"){
        return  <div data-testid={ERROR_COMPONENT_TEST_ID.api} ><ErrorApiProblem error={error} /></div>
    } else if(error?.type === "no-content") {
        return  <div data-testid={ERROR_COMPONENT_TEST_ID.content} ><ErrorContent error={error} /></div>
    }
    return undefined
}

const ErrorBoundaries: React.FC<ErrorBounderiesProps> = ({error, children, fallbackComponent }) => {
    const errorMessage = useMemo(() => convertErrorTypeToMessageView(error),[error])
    if(fallbackComponent && error){
        return <Fragment>{fallbackComponent}</Fragment>
    }
    if(!fallbackComponent && errorMessage){
        return <div>{errorMessage}</div>
    }
    return <Fragment>{children}</Fragment>
}

export default ErrorBoundaries

export interface UseGetErrorComponentArgument {
    error?: ErrorApplicationData;
    isPage? : boolean;
    emptyComponent?: ReactElement | null;
}

export const useGetErrorComponent = ({error, isPage, emptyComponent}: UseGetErrorComponentArgument): ReactElement => {

    const component: ReactElement = useMemo(() => {
        return isPage
            ? <Container>
                <SpacingMenu>
                    <Container className="page-empty">
                        {error?.type === "no-content" && emptyComponent
                            ? emptyComponent
                            : convertErrorTypeToMessageView(error)}
                    </Container>
                </SpacingMenu>
            </Container>
            : <div> {error?.type === "no-content" && emptyComponent
                ? emptyComponent
                : convertErrorTypeToMessageView(error)}
            </div>
    },[error, isPage, emptyComponent])

    return component
}

export const getErrorComponent = ({error, isPage, emptyComponent}: UseGetErrorComponentArgument): ReactElement | undefined => {

    if(error === undefined) {
        return undefined
    }
    return isPage
        ? <Container>
            <SpacingMenu>
                <Container className="page-empty">
                    {error?.type === "no-content" && emptyComponent
                        ? emptyComponent
                        : convertErrorTypeToMessageView(error)}
                </Container>
            </SpacingMenu>
        </Container>
        : <div> {error?.type === "no-content" && emptyComponent
            ? emptyComponent
            : convertErrorTypeToMessageView(error)}
        </div>
}

