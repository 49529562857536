import {GetPagesItemData} from "../../../data-access/api-call/wagtail-rest-page-api/page";
import {ApiObjectData} from "../../../data-access/api-call/wagtail-rest-page-api/models/ApiCallModel";
import * as yup from 'yup'
import {requiredMessage} from "../../../models/error/ModelErrorValidation";
import {ObjectSchema} from "yup";

export interface AcknowledgmentPersonData {
    first_name: string;
    last_name: string;
    email?: string;
}

export const acknowledgmentPersonDataSchema = yup.object({
    first_name: yup.string().required(requiredMessage),
    last_name: yup.string().required(requiredMessage),
    email: yup.string()
})

export interface SocialMediaData {
    url: string;
    type: string;
}

export const socialMediaDataSchema = yup.object({
    url: yup.string().required(requiredMessage),
    type: yup.string().required(requiredMessage)
})

export interface FounderData {
    name: string;
    url: string;
}

export const founderDataSchema = yup.object({
    name: yup.string().required(requiredMessage),
    url: yup.string().required(requiredMessage)
})


export interface CopyrightNoticeData {
    notice: string
}

export const copyrightNoticeDataSchema = yup.object({
    notice: yup.string().required(requiredMessage)
})

export interface CopyrightData {
    notice: Array<CopyrightNoticeData>;
    date_begin: string;
}

export const copyrightDataSchema = yup.object({
    notice: yup.array(copyrightNoticeDataSchema),
    date_begin: yup.string().required(requiredMessage)
})


export interface PrivacyAndTermsData {
    label: string;
    path: string;
}

export const privacyAndTermsDataSchema = yup.object({
    label: yup.string().required(requiredMessage),
    path: yup.string().required(requiredMessage)
})

export interface CookiesPreferencesData {
    label: string;
    path: string;
}

export const cookiesPreferencesSchema = yup.object({
    label: yup.string().required(requiredMessage),
    path: yup.string().required(requiredMessage)
})


export interface FooterModelData extends GetPagesItemData{
    project_title: string,
    project_description: string,
    acknowledgment?: Array<ApiObjectData<AcknowledgmentPersonData, "person">>,
    social_media?: Array<ApiObjectData<SocialMediaData, "social_media">>
    founder?: Array<ApiObjectData<FounderData, "founder">>
    copyright?: Array<ApiObjectData<CopyrightData, "copyright">>,
    privacy_and_terms?: Array<ApiObjectData<PrivacyAndTermsData, "privacy_and_terms">>,
    cookie_preference?: Array<ApiObjectData<CookiesPreferencesData, "cookie_preference">>
}


// @ts-ignore
export const footerModelDataSchema: ObjectSchema<any> = yup.object({
    project_title: yup.string().required(requiredMessage),
    project_description: yup.string().required(requiredMessage),
    acknowledgment: yup.array(yup.object({
        id: yup.string().required(requiredMessage),
        type: yup.string().required(requiredMessage),
        value: acknowledgmentPersonDataSchema
    })),
    social_media: yup.array(yup.object({
        id: yup.string().required(requiredMessage),
        type: yup.string().required(requiredMessage),
        value: socialMediaDataSchema
    })),
    founder: yup.array(yup.object({
        id: yup.string().required(requiredMessage),
        type: yup.string().required(requiredMessage),
        value: founderDataSchema
    })),
    copyright: yup.array(yup.object({
        id: yup.string().required(requiredMessage),
        type: yup.string().required(requiredMessage),
        value: copyrightDataSchema
    })),
    privacy_and_terms:yup.array(yup.object({
        id: yup.string().required(requiredMessage),
        type: yup.string().required(requiredMessage),
        value: privacyAndTermsDataSchema
    })),
    cookie_preference: yup.array(yup.object({
        id: yup.string().required(requiredMessage),
        type: yup.string().required(requiredMessage),
        value: cookiesPreferencesSchema
    })),
})