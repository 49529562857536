import {RouteObject, useLocation} from "react-router-dom";
import LandingPage from "../components/page/page-model/landingPage/LandingPage";
import Page from "../components/page/Page";
import {useLayoutEffect} from "react";
import UnSubscribePage from "../components/page/page-model/unsubscribe-page/UnSubscribePage";

export interface RouteContent {[key: string]: {path: string, id: string}}

/**
 * Description - route path
 */
export const routes: RouteContent = {
    home: {path: "/", id: "home"},
    unsubscribe: {path: "/unsubscribe", id: "unsubscribe"},
}

/**
 * Description - test id to catch main component
 */
export const testIDs = {
    home: "home-page",
    unsubscribe: "unsubscribe-page"
}

/**
 * Description: service to scroll to the top when user navigate
 */
export const useScrollTopNavigation = () => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
}

/**
 * Description - route node to map route path with main component
 */
export const routeNode: Array<RouteObject> = [
    {path: routes.home.path, element: <div data-testid={testIDs.home}><LandingPage/></div> },
    {path: routes.unsubscribe.path, element: <div data-testid={testIDs.unsubscribe}><UnSubscribePage/></div>},
    {path: "/:pageId", element: <Page/>}
]

