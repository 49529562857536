import React from "react";

export interface PreviewContextType {
    isPreview: boolean
}

const PreviewContext = React.createContext<PreviewContextType>({
    isPreview: true
})

export const PreviewContextProvider = PreviewContext.Provider;

export default PreviewContext