import * as yup from "yup"
import {ObjectSchema} from "yup";
import { GetPagesItemData } from "../../../../../data-access/api-call/wagtail-rest-page-api/page/models/PageModel";

export interface BasicPageModel extends GetPagesItemData{
    title: string;
    content?: string
}

// @ts-ignore
export const basicPageSchemaValidation: ObjectSchema<any> = yup.object({
    title: yup.string().required(),
    content: yup.string().nullable()
})      