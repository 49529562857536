import {ErrorApplication} from "../../models/error/ErrorModel";

export interface MappingVariables {
    key: string,
    optional?: boolean,
    serializer?: (params: any) => any
}

export interface Mapping {
    [key: string]: string | MappingVariables
}

/**
 * Description - Method to convert data with validation.
 * @param key
 * @param value
 * @param optional
 */
export const convertValueInValidationContext = <TValue = unknown>(key: string, value: TValue, optional?: boolean): TValue | undefined => {
    if(!optional){
        if(value === "" || (value instanceof Object && !Object.keys(value).length) || (Array.isArray(value) && !value.length)){
            throw Error(`${key} is required`)
        }
        return value
    }
    if(value === ""){
        return undefined
    }
    return value
}

/**
 * Description - Method to map object with mapping configuration
 * @param objectToMap
 * @param mapping
 */
export const mapper = <TResult extends Object = Object, TObject extends Object = Object>(objectToMap: TObject, mapping: Mapping):TResult => {
    const result: TResult = Object.create({});
    if(mapping){
        const objectInMap = new Map<string, any>(Object.entries(objectToMap))
        Object.keys(mapping).forEach(key => {
            const mappingValue = mapping[key]
            const keyValue =  typeof mappingValue === "string" ? mappingValue : mappingValue.key
            const optional = typeof mappingValue === "string" ? undefined: mappingValue.optional
            const serializer = typeof mappingValue === "string" ? undefined: mappingValue.serializer
            if(!objectInMap.get(keyValue) && !optional){
                throw new ErrorApplication(`${keyValue} doesn't exist`, "validation")
            } else {
                const value = convertValueInValidationContext(keyValue, objectInMap.get(keyValue), optional)
                // @ts-ignore
                result[key] = serializer && value
                    ? serializer(value)
                    : value
            }
        })
    }
    return result

}