import apiCaptchaClient from "../ApiCaptchaClient";
import {CaptchaModelData} from "./models/CaptchaModelData";

export const GET_CAPTCHA_URL = "/captcha/refresh/"

export const getCaptcha = () => {
    return new Promise<CaptchaModelData>((resolve, reject) => {
        apiCaptchaClient.get<CaptchaModelData | undefined>(GET_CAPTCHA_URL).then(response => {
            if(response.data){
                resolve(response.data)
            } else {
                reject({
                    type: "no-content",
                    message: "No content during captcha refreshing"
                })
            }
        }).then(error => reject(error))
    })
}