import {NewsModelApiCustomView} from "../../../../../news/models/NewsModelView";
import React from "react";
import {Button, Stack} from "react-bootstrap";
import TagChips from "../../../../../ui/chips/tag-chips/TagChips";
import DateInfo from "../../../../../ui/date/date-info/DateInfo";
import {useTranslation} from "react-i18next";
import DefaultCard from "../../../../../ui/card/DefaultCard";
import OverlayToolTip from "../../../../../ui/tooltip/OverlayToolTip";
import {usePreview} from "../../../../../../preview/PreviewService";
import {useGetImage} from "../../../../../image/ImageApplicationData";
import {Link} from "react-router-dom";

export const READ_MORE_BUTTON_KEY_LABEL = "ui.newCard.readMoreButton"

export interface NewsCardProps {
    content: NewsModelApiCustomView,
    selectTheme: (id: number) => void,
    selectTag: (tagName: string) => void
}

/**
 * Description - View component to display news information in card
 * @param title
 * @param abstract
 * @param tag
 * @param published_date
 * @param url
 * @param themes
 * @param image
 * @param selectTheme
 * @param selectTag
 * @constructor
 */
const NewsCard: React.FC<NewsCardProps> = ({content:{title,abstract, tag, published_date, url, detail_page_id, themes, image}, selectTheme, selectTag}) => {

    const {t} = useTranslation()
    const {isPreview} = usePreview()

    const {data: imageData} = useGetImage(image)

    return <DefaultCard
        id={title}
        header={title}
        content={abstract}
        contentExpandable
        image={imageData}
        customSectionComponent={<Stack className="stack-responsive" direction="horizontal">
            <div>
                {themes !== null && themes.map(({name, id}, index) => (
                    // display interval / before if not the first
                    <span
                        key={id}
                        onClick={!isPreview ? () => selectTheme(id) : undefined}>
                        <OverlayToolTip label={!isPreview ? `${t("page.newPage.newsCard.tooltipThemeChip")} ${name}`: "disabled in preview mode"}>
                            <TagChips clickable={!isPreview} content={name} first={index === 0} lastWithInterval={index + 1 === themes.length && tag.length === 0} />
                        </OverlayToolTip>
                    </span>
                ))}
                {tag.map((tagText, index) =>(
                    // display interval / after if is the last
                    <span
                        key={tagText}
                        onClick={!isPreview ? () => selectTag(tagText) : undefined}
                    >
                        <OverlayToolTip
                            label={!isPreview ? `${t("page.newPage.newsCard.tooltipTagChip")} ${tagText}` : "disabled in preview"}
                        >
                            <TagChips clickable={!isPreview} variant="accent" content={tagText} first={!themes.length} lastWithInterval={index + 1 === tag.length} />
                        </OverlayToolTip></span>
                ))}
            </div>
            <DateInfo date={published_date}/>
        </Stack>}
        buttonActionComponent={!isPreview
            ? detail_page_id
                ? <Link to={`/${detail_page_id}`}><Button role="link" variant="outline-secondary">{t(READ_MORE_BUTTON_KEY_LABEL)}</Button></Link>
                : <a target="_blank" rel="noopener noreferrer" href={url}>
                    <Button role="link" variant="outline-secondary" >
                        {t(READ_MORE_BUTTON_KEY_LABEL)}
                    </Button>
                </a>
            : <Button disabled variant="outline-secondary">{t(READ_MORE_BUTTON_KEY_LABEL)}</Button>
        }
    />
}

export default NewsCard;