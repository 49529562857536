import React, {useMemo} from "react";
import parse from 'html-react-parser';

export interface RichTextProps {
    className?: string;
    content: string;
    dataTestId?: string;
}
const regexExternalLink = /<a href=\\*"([A-Za-z:/.%0-9_\-#]*)\\*">/gm
const regexInternLinkPage = /<a id=\\*"\\*(\d*)\\*"\\* linktype="page">/gm

/**
 * Description - UI Component to render rich text html string format
 * @param className
 * @param content
 * @param dataTestId
 * @constructor
 */
const RichText: React.FC<RichTextProps> = ({className, content, dataTestId}) => {
    const contentTransform = useMemo(() => {
        return content
            // add properties to open external link to another tab
            .replace(regexExternalLink, '<a target="_blank" rel="noreferrer" href="$1">')
            // replace wagtail id property to href property to redirect correctly in intern page
            .replace(regexInternLinkPage,'<a href="$1">')
    }, [content])
    return <div className={`rich-text${className ? ` ${className}` : ""}`} data-testid={dataTestId ?dataTestId : undefined}>
        {parse(contentTransform)}
    </div>
}

export default RichText