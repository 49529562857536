import {PageDispatchingModel} from "../../../page-dispatching/models/PageDispatchingModel";
import {LandingPageModelData, landingPageModelDataSchema} from "../models/LandingPageModelData";
import {LandingPageModelView} from "../models/LandingPageModelView";
import {convertLandingPageDataToLandingPageView, LANDING_PAGE_WAGTAIL_MODEL_NAME} from "../LandingPageData";
import {LandingPageView} from "../LandingPage";

export const defaultLandingPageDispatcher: PageDispatchingModel<LandingPageModelData, LandingPageModelView> = {
    wagtail_model_name: LANDING_PAGE_WAGTAIL_MODEL_NAME,
    serializer: convertLandingPageDataToLandingPageView,
    component: (view: LandingPageModelView) => <LandingPageView data={view}/>,
    schemaValidation: landingPageModelDataSchema
}