import React, {ReactNode, useMemo} from "react";
import i118nConfiguration from "./i118nConfiguration";
import {I18nextProvider, useTranslation} from "react-i18next";

export const languages: Array<string> = ["fr", "nl", "en", "de"];

export const defaultLanguage = "nl";

export interface TranslationContextType {
    language: string,
    onChangeLanguage: (languageCode: string) => void,
    t: (key: string) => void,
    languages: Array<string>
}

export const TranslationContext = React.createContext<TranslationContextType>({
    language: defaultLanguage,
    onChangeLanguage: (_language: string) => {},
    t: (_key: string) => {},
    languages
})

export const TranslationContextProvider = TranslationContext.Provider

/**
 * Description - Service to manage language
 *
 * Remark - It is an implementation of i118n hook
 */
export const useTranslationService = (): TranslationContextType => {
    const {i18n, t} = useTranslation()

    const language = useMemo(() => {
        // handle language code from language detector (fr-FR => fr)
        let value: string = ""
        languages.forEach(regex => {
            if(i18n.language.match(regex)){
                value = regex
            }
        })
        if(value === ""){
            value= defaultLanguage;
        }
        return value
    },[i18n.language])

    return {onChangeLanguage: i18n.changeLanguage, language, t, languages}
}
export const TranslationServiceProvider: React.FC<{children: ReactNode}> = ({children}) => {
    const {language, onChangeLanguage, t, languages} = useTranslationService()

    return <TranslationContextProvider value={{language: language, onChangeLanguage, t, languages}}>
        {children}
    </TranslationContextProvider>
}

/**
 * Description - Service provider for translation
 *
 * - Implement I18next Provider to init i118 configuration
 * - Implement Translation service provider to inject translation service for translation management
 * @param children
 * @constructor
 */
const TranslationProvider: React.FC<{children: ReactNode}> = ({children}) => <I18nextProvider i18n={i118nConfiguration}>
    <TranslationServiceProvider>
        {children}
    </TranslationServiceProvider>
</I18nextProvider>
export default TranslationProvider

