import {ActorModelView, GetActorModelView} from "./models/ActorItemModelView";
import {
    GetActorData,
    getActorSchema
} from "../../data-access/api-call/wagtail-rest-snippet-api/actor/models/ActorModel";
import {useApiQuery} from "../../data-access/data-caching";
import {useContext, useMemo} from "react";
import ApiCallContext from "../../data-access/api-call/ApiCallContext";
import {ActorModelData} from "./models/ActorModelData";

export const ACTOR_SNIPPET_QUERY_NAME = "get_actor"
export const ACTOR_SNIPPET_MODEL_NAME = "Actor"

/**
 * Description - Method to convert actor model data to model view
 * @param data
 */
export const convertActorModelDataToActorModelView = (data: ActorModelData): ActorModelView => {
    const {id, name, acronym, content, url, logo} = data;
    return {
        id: id.toString(),
        name,
        acronym,
        content,
        url,
        logo: logo.toString()
    }
}

/**
 * Description - Method to convert actor model data to model view
 * @param data
 */
export const convertGetActorModelDataToActorModelView = (data: GetActorData): GetActorModelView => {
    const {id, name, acronym, content, url, logo} = data;
    return {
        id: id.toString(),
        name,
        acronym,
        content,
        url,
        logo: logo.toString()
    }
}

/**
 * Description - Service to fetch actor snippet from wagtail rest api
 * @param id
 */
export const useGetActor = ({id}:{id: string}) => {
    const {getActor} = useContext(ApiCallContext)
    const {data, isLoading, error} = useApiQuery<GetActorData>(ACTOR_SNIPPET_QUERY_NAME, getActor, {
        variables: id,
        schemaValidation: getActorSchema,
        model: ACTOR_SNIPPET_MODEL_NAME
    })

    const actorView = useMemo(() => {
        return data && !isLoading ? convertGetActorModelDataToActorModelView(data) : undefined
    }, [data, isLoading])

    return {data: actorView, isLoading, error}
}