import React from "react";
import {Button, Modal, Spinner} from "react-bootstrap";
import ComponentSwitch from "../ui/component-switch/ComponentSwitch";
import {useGetGdprPage} from "./GDPRData";
import RichText from "../ui/RichText/RichText";
import {useTranslation} from "react-i18next";
import {GDPRModelView} from "./models/GDPRModel";

export interface GDPRDialogViewProps {
    open: boolean;
    onClose: () => void;
    data: GDPRModelView
}

/**
 * Description - View Component to display GDPR information in dialog
 * @param data
 * @param onClose
 * @param open
 * @constructor
 */
export const GDPRDialogView: React.FC<GDPRDialogViewProps> = ({data, onClose, open}) => {

    const {t} = useTranslation()

    return <React.Fragment>
        <Modal.Header closeButton>
            <Modal.Title>{data.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body><RichText content={data.content}/></Modal.Body>
        <Modal.Footer>
            <Button variant="outline-primary" onClick={onClose}>
                {t("action.close")}
            </Button>
        </Modal.Footer>
    </React.Fragment>
}

export interface GDPRDialogProps {
    open: boolean,
    onClose: () => void
}


/**
 * Description - UI Component to render GDPR dialog
 *  - implement service to fetch gdpr data from rest api
 *  - render dialog to display gdpr information
 * @param open
 * @param onClose
 * @constructor
 */
const GDPRDialog: React.FC<GDPRDialogProps> = ({open, onClose }) => {

    const {data, isLoading, error} = useGetGdprPage()

    return <Modal size="lg" show={open} onHide={onClose}>
        <ComponentSwitch error={error} loading={isLoading} loadingComponent={<Spinner animation="border" />}>
            {data !== undefined && <GDPRDialogView data={data} open={open} onClose={onClose} />}
        </ComponentSwitch>

    </Modal>
}

export default GDPRDialog