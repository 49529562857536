import {getThemeItemDataSchema, GetThemesData} from "./models/ThemeModel";
import apiWagtailSnippetClient from "../ApiWagtailSnippetClient";
import {ErrorApplicationData} from "../../../../models/error/ErrorModel";

/**
 * Description - Method to validate theme item data from custom theme wagtail rest api
 * @param data
 */
const validateThemesItem = (data: GetThemesData):Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        const validations: Array<Promise<void>> = data.results.map(item => {
            return new Promise((resolveValidation, rejectValidation) =>  {
                getThemeItemDataSchema.validate(item, {abortEarly: false}).then(() => {
                    resolveValidation()
                }).catch(errors => {
                    const errorsMessage: {[key: string]: string} = {}
                    errors.inner.forEach((e: {path: string, message: string}) => {
                        errorsMessage[e.path] = e.message
                    })
                    rejectValidation(errorsMessage)
                })
            })
        })
        return Promise.all(validations).then(() => resolve()).catch((errors) => reject({
            type: "validation",
            message: {
                title: "data validation",
                content: {...errors}
            }
        }))
    })
}

export const GET_THEME_API_URL = "/themes"

/**
 * Description - Process to fetch all theme from custom theme wagtail rest api
 */
export const getThemes = (): Promise<GetThemesData> => {
    return new Promise<GetThemesData>((resolve, reject)=> {
        apiWagtailSnippetClient.get<GetThemesData>(GET_THEME_API_URL).then(response => {
            if(!response.data.results.length){
                const noContentError: ErrorApplicationData = {
                    type: "no-content",
                    message: "they are no content for this model",
                }
                reject(noContentError)
            } else {
               validateThemesItem(response.data).then(() => resolve(response.data)).catch(error => reject(error))
            }

        }).catch(error => reject(error))
    })
}