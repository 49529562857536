import React from "react";
import {Container, Navbar} from "react-bootstrap";
import LanguageMenu from "./language_menu/LanguageMenu";
import FedRightSide from "./fed-right-side/FedRightSide";

/**
 * Description - UI Component to render federal top bar
 *  - render language menu
 *  - render federal information and link
 * @constructor
 */
const FedTopBar: React.FC = () => (
    <React.Fragment>
        <Navbar className="FedTopBar">
            <Container fluid>
                <LanguageMenu/>
                <FedRightSide/>
            </Container>
        </Navbar>
    </React.Fragment>
)

export default FedTopBar