import React, {useCallback, useState} from "react";
import SpacingMenu from "../../../ui/spacing/SpacingMenu";
import NewsSearchForm from "./ui/news-search-form/NewsSeachForm";
import {OnSearchVariables, SEARCH_NEWS_PAGINATION_PAGE_SIZE, useSearchNews} from "../../../news/NewsData";
import {useTranslation} from "react-i18next";
import {Container} from "react-bootstrap";
import {NewsSearchDataBlock, useSearchNewsVariables} from "../../../news/components/NewsSearchDataBlock";

export interface OnSearchFormData {
    titleQuery: string;
    tag: string;
    theme: number | "";
}


export interface NewsPageViewProps {
    onSearch: (variables: OnSearchFormData) => void;
    pages: Array<OnSearchVariables>;
    selectTheme: (id: number) => void;
    selectTag: (tagName: string) => void;
    onNext: () => void;
}

export const NEWS_PAGE_EMPTY_TEST_ID = {
    container: "news-page-empty"
}

/**
 * Description - UI Component to render empty page when the result of search is empty
 * @constructor
 */
export const NewsPageEmpty: React.FC = () => {
    const {t} = useTranslation()
    return <Container>
        <div data-testid={NEWS_PAGE_EMPTY_TEST_ID.container} className="page-news-list-empty">
            {t("page.newPage.noResult")}
        </div>
    </Container>
}

/**
 * Description - View Component display news information
 * @param onSearch
 * @param pages
 * @param selectTheme
 * @param selectTag
 * @param onNext
 * @constructor
 */
export const NewsPageView: React.FC<NewsPageViewProps> = ({onSearch, pages, selectTheme,  selectTag, onNext}) => {

    return <div>
        <SpacingMenu>
            <NewsSearchForm
                onSearch={onSearch}
            />
            {pages.map((page, index ) => <NewsSearchDataBlock key={`${page.offset}-${page.tags}-${page.search}-${page.theme}`} isSingle={index === pages.length -1} onNext={onNext} selectTheme={selectTheme} selectTag={selectTag} variables={page} />)}
        </SpacingMenu>
    </div>
}

/**
 * Description - Service to fetch news by filtering with search form value
 */
export const useNewsPageSearch = () => {

    const [searchFormValue, setSearchFormValue] = useState<OnSearchFormData | undefined>()
    const {data, isLoading, error, onNext} = useSearchNews(searchFormValue)

    const onSubmitSearchForm = useCallback((searchFormInsert: OnSearchFormData) => {
        setSearchFormValue(searchFormInsert)
    },[])

    return {onSubmitSearchForm, data, error, isLoading, onNext}
}

/**
 * Description - Component to render news page
 *  - implement service to fetch news with filter from wagtail rest api
 *  - rendering in loading state
 *  - rendering news page view component
 * @constructor
 */
const NewsPage: React.FC = () => {

    const {onNext, pages, onChangeVariables, onChangeTheme, onChangeTag} = useSearchNewsVariables({defaultVariables:{offset: 0, limit: SEARCH_NEWS_PAGINATION_PAGE_SIZE}})

    const onSubmitSearchForm = useCallback((formData: OnSearchFormData) => {
        onChangeVariables({
            tags: formData.tag,
            search: formData.titleQuery,
            theme: formData.theme !== "" ? formData.theme.toString() : undefined
        })
    }, [onChangeVariables])

    return <NewsPageView onSearch={onSubmitSearchForm} pages={pages} selectTheme={(theme) => onChangeTheme(theme.toString())} selectTag={onChangeTag} onNext={onNext}/>
}

export default NewsPage