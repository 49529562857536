import {DefaultProviderModel} from "../../../utils/provider/DefaultProviderModel";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import React from "react";
import {isMobile} from "react-device-detect"

export interface OverlayToolTipProps extends DefaultProviderModel {
    label: string;
    disabled?: boolean;
}

const OverlayToolTip: React.FC<OverlayToolTipProps> = ({children, label, disabled}) => {
    return !isMobile && !disabled
        ? <OverlayTrigger
            overlay={<Tooltip>{label}</Tooltip>}
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
        >
        <span>
            {children}
        </span>
        </OverlayTrigger>
        :  <span>
            {children}
        </span>
}

export default OverlayToolTip;