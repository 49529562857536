import * as yup from "yup"
import {requiredMessage} from "../../../../../models/error/ModelErrorValidation";

export interface GetActorData {
    id: number;
    name: string;
    acronym?: string;
    content: string;
    logo: number;
    url: string;
    locale: string;
}

// @ts-ignore
export const getActorSchema = yup.object({
    id: yup.number().required(requiredMessage),
    name: yup.string().required(requiredMessage),
    acronym: yup.string(),
    content: yup.string().required(requiredMessage),
    logo: yup.number().required(requiredMessage),
    url: yup.string().required(requiredMessage),
    locale: yup.string().required(requiredMessage),
})