import {GetPagesItemData} from "../../../data-access/api-call/wagtail-rest-page-api/page";
import * as yup from "yup";
import {requiredMessage} from "../../../models/error/ModelErrorValidation";
import {ObjectSchema} from "yup";
import {
    ApiImageData,
    apiImageDataSchema
} from "../../../data-access/api-call/wagtail-rest-page-api/image/models/ImageModel";
import {
    GetThemeItemData,
    getThemeItemDataSchema
} from "../../../data-access/api-call/wagtail-rest-snippet-api/theme/models/ThemeModel";

export interface NewsThemeModelData {
    id: number;
    meta: {
        type: "news.NewsTheme"
    },
    theme: GetThemeItemData
}

export interface NewsModelApiCustomData extends GetPagesItemData{
    title: string;
    abstract: string;
    published_date: string;
    themes: Array<GetThemeItemData> | null;
    tags: Array<string>;
    source_url?: string;
    source_url_internal?: number;
    image: number;
}

// @ts-ignore
export const newsValidationApiCustomSchema: ObjectSchema<any> = yup.object({
    title: yup.string().required(requiredMessage),
    abstract: yup.string().required(requiredMessage),
    published_date: yup.string().required(requiredMessage),
    themes: yup.array(getThemeItemDataSchema).nullable(),
    tags: yup.array(yup.string()).nullable(),
    source_url: yup.string().nullable(),
    source_url_internal: yup.number().nullable(),
    image: yup.number().nullable()
})

export interface NewsModelData extends GetPagesItemData{
    title: string;
    abstract: string;
    published_date: string;
    news_page_theme: Array<NewsThemeModelData>;
    tags: Array<string>;
    source_url?: string;
    source_url_internal?: number;
    image: ApiImageData;
}

// @ts-ignore
export const newsValidationSchema: ObjectSchema<any> = yup.object({
    title: yup.string().required(requiredMessage),
    abstract: yup.string().required(requiredMessage),
    published_date: yup.string().required(requiredMessage),
    news_page_theme: yup.array(yup.object({
        theme: getThemeItemDataSchema.required(requiredMessage).nullable()
    })).required(requiredMessage).nullable(),
    tags: yup.array(yup.string()).required(requiredMessage).nullable(),
    source_url: yup.string().nullable(),
    source_url_internal: yup.object().nullable(),
    image: apiImageDataSchema.nullable()
})