import React from "react";
import {Spinner} from "react-bootstrap";
import SpacingMenu from "../ui/spacing/SpacingMenu";

export const PageContentLoading: React.FC = () => {
    return <div data-testid={PAGE_LOADING_TEST_ID.container} className="page-loading page-empty">
        <Spinner animation="border"/>
    </div>
}

export const PAGE_LOADING_TEST_ID = {
    container: "page-loading-container",
    content: "page-loading-content",
}


/**
 * Description - View component to display in page loading state
 * @constructor
 */
const PageLoading: React.FC = () => {
    return (
        <div data-testid={PAGE_LOADING_TEST_ID.container} className="page-loading-container">
            <SpacingMenu>
                <PageContentLoading/>
            </SpacingMenu>
        </div>
    )
}

export default PageLoading