import {PageDispatchingModel} from "../../../page-dispatching/models/PageDispatchingModel";
import { BASIC_PAGE_WAGTAIL_MODEL_NAME } from "../BasicPageData";
import { BasicPageView } from "../BasicPageView";
import { BasicPageModel, basicPageSchemaValidation } from "../model/BasicPageModel";


export const defaultBasicPageDispatcher: PageDispatchingModel<BasicPageModel, BasicPageModel> = {
    wagtail_model_name: BASIC_PAGE_WAGTAIL_MODEL_NAME,
    serializer: data => data,
    component: (view: BasicPageModel) => <BasicPageView data={view} />,
    schemaValidation: basicPageSchemaValidation
}