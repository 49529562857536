import {headerDataSchema, HeaderModelData} from './models/HeaderModelData'
import {HeaderModelView} from './models/HeaderModelView'
import {useContext, useMemo} from "react";
import ApiCallContext from "../../data-access/api-call/ApiCallContext";
import {useApiQuery} from "../../data-access/data-caching";
import {GetPagesData} from "../../data-access/api-call/wagtail-rest-page-api/page";
import {ErrorApplicationData} from "../../models/error/ErrorModel";
import {convertApiImageDataToImageAttributs} from "../../data-access/api-call/wagtail-rest-page-api/utils";
export const HEADER_WAGTAIL_MODEL_NAME = "header.HeaderPage"
export const HEADER_QUERY_NAME = "get_header"
export const HEADER_MODEL_NAME = "Header"

/**
 * Description - Method to convert footer models data from data access to models view
 * @param data
 */
export const convertHeaderDataToHeaderView = (data: HeaderModelData): HeaderModelView => {
    const {title, subtitle, logo, background_image} = data

    return {
        title,
        subtitle,
        backgroundImage:convertApiImageDataToImageAttributs(background_image),
        logo: convertApiImageDataToImageAttributs(logo)
    }

}


/**
 * Description - Service to fetch header data from wagtail rest api
 */
export const useGetHeader = ():{data: HeaderModelView| undefined, isLoading: boolean, error?: ErrorApplicationData} => {
    const {getPages} = useContext(ApiCallContext);
    const {data, isLoading, error} = useApiQuery<GetPagesData<HeaderModelData>>(HEADER_QUERY_NAME, getPages, {
        variables: {
            fields: "*",
            type: HEADER_WAGTAIL_MODEL_NAME,
        },
        schemaValidation: headerDataSchema,
        model: HEADER_MODEL_NAME
    })

    const headerView = useMemo(() => {
        return data && data.items.length && !isLoading ? convertHeaderDataToHeaderView(data.items[0]) : undefined
    }, [data, isLoading])

    return {data: headerView , isLoading, error}
}
