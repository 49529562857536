import {useApiQuery} from "../../data-access/data-caching";
import {useContext, useMemo} from "react";
import ApiCallContext from "../../data-access/api-call/ApiCallContext";
import {GDPRModel, gDPRModelDataSchema} from "./models/GDPRModel";
import {GetPagesData} from "../../data-access/api-call/wagtail-rest-page-api/page";

export const GDPR_WAGTAIL_MODEL_NAME = "core.GdprPage"
export const GDPR_QUERY_NAME = "get_gdpr_page"
export const GDPR_MODEL_NAME = "Gdpr Page"

/**
 * Description - Service to fetch GDPR data from wagtail rest api
 */
export const useGetGdprPage = () => {
    const {getPages}= useContext(ApiCallContext)
    const {data, isLoading, error} = useApiQuery<GetPagesData<GDPRModel>>(GDPR_QUERY_NAME, getPages, {
        variables: {
            type: GDPR_WAGTAIL_MODEL_NAME,
            fields: "*",
        },
        schemaValidation: gDPRModelDataSchema,
        model: GDPR_MODEL_NAME
    })

    const grpdView = useMemo(() => {
         return data && !isLoading ? data.items[0] : undefined
    },[data, isLoading])

    return {data: grpdView, isLoading, error}
}