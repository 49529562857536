import {ThemePageModelView} from "./models/ThemePageModelView";
import {Container, Stack} from "react-bootstrap";
import RichText from "../../../ui/RichText/RichText";
import React from "react";
import SpacingMenu from "../../../ui/spacing/SpacingMenu";
import {ThemeService} from "./ui/ThemeService";

export interface ThemePageViewProps {
    data: ThemePageModelView
}

/**
 * Description - View component to display theme information in theme page
 * @param title
 * @param image
 * @param description
 * @param services
 * @constructor
 */
export const ThemePageView: React.FC<ThemePageViewProps> = ({data: {title,image, description, services }}) => {

    return <SpacingMenu>
        <div className="theme-page">
            <Container className="theme-content" >
                <div className="theme-title-container padding-top-1 padding-bottom-1">
                        <h1 className="margin-bottom-0 padding-left-1">{title}</h1>
                </div>
                <div>
                        <Stack className="stack-responsive padding-bottom-2" direction="horizontal" gap={2} >
                            <div className="padding-top-1 padding-bottom-1 padding-left-1 padding-right-1 theme-description-container"><RichText content={description}/></div>
                            <div className="theme-icon" style={{backgroundImage: `url(${image.imageUrl})`}}/>
                        </Stack>
                </div>
            </Container>
            <Container className="margin-top-2">
                {services.map((service) => <ThemeService key={service.title} data={service} />)}
            </Container>
        </div>
    </SpacingMenu>

}