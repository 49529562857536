import {PageDispatchingModel} from "./models/PageDispatchingModel";
import {GetPagesItemData} from "../../../data-access/api-call/wagtail-rest-page-api/page";
import {ReactElement, ReactNode, useEffect, useMemo, useState} from "react";
import {ErrorApplicationData} from "../../../models/error/ErrorModel";
import {useTranslationService} from "../../../translation/TranslationProvider";

export const getPageDispatching = (data_wagtail_model_name: string, pageDispatchingList: Array<PageDispatchingModel<any, any>>) => {
    return pageDispatchingList.find((pageDispatching) => data_wagtail_model_name === pageDispatching.wagtail_model_name)
}

/**
 * Description - Service to dispatch page view by wagtail model name
 * @param data
 * @param pageDispachingList
 */
export const usePageDispatcher = ({data, pageDispatchingList} : { data?: GetPagesItemData, pageDispatchingList: Array<PageDispatchingModel>} ) => {

    const [error, setError] = useState<ErrorApplicationData | null>(null)
    const [viewData, setViewData] = useState<unknown | undefined>(undefined)
    const {language} = useTranslationService()

    const pageDispatching = useMemo(() => {
        if(data){
            const currentPageDispatching = getPageDispatching(data.meta.type, pageDispatchingList)
            if(currentPageDispatching){
                return currentPageDispatching
            }
            setError({
                type: "run",
                message: `the page dispatcher doesn't find a page dispatching with the model${data.meta.type}`
            })
        }
        return undefined
    },[data, pageDispatchingList])

    useEffect( () => {
        if(data && pageDispatching){
            if(pageDispatching.schemaValidation){
                pageDispatching.schemaValidation.validate(data).then(() => {
                    if(pageDispatching.serializer){
                        try {
                            setViewData((current: unknown) => {
                                if(pageDispatching.serializer) {
                                    const newViewData = pageDispatching.serializer(data, language)
                                    return newViewData
                                }
                                return current
                            })
                        } catch (_error){
                            setError({
                                type: "run",
                                message: {
                                    title: `Error during serialization of the model ${data.meta.type}`,
                                    // @ts-ignore
                                    content: _error.message
                                }
                            })
                        }
                    } else {
                        setViewData(data)
                    }
                }).catch(errorValidation => {
                    setError({
                        type: "run",
                        message: {
                            title: `the data of the model ${data.meta.type} is not valid`,
                            content:  errorValidation
                        }
                    })
                })
            } else {
                if(pageDispatching.serializer){
                    try {
                        setViewData(pageDispatching.serializer(data, language))
                    } catch (_error){
                        setError({
                            type: "run",
                            message: {
                                title: `Error during serialization of the model ${data.meta.type}`,
                                // @ts-ignore
                                content: _error.message
                            }
                        })
                    }
                } else {
                    setViewData(data)
                }
            }
        }
    },[pageDispatching, data, language])

    const renderComponent: ReactElement | ReactNode | null = useMemo(() => {
        if(viewData && pageDispatching){
            try {
                return pageDispatching.component(viewData)
            } catch(_error){
                setError({
                    type: "run",
                    message: {
                        title: "Error to generate view component",
                        // @ts-ignore
                        content: _error.message
                    }
                })
            }
        }
        return null
    },[pageDispatching, viewData])

    return {component : renderComponent, error}
}