import * as yup from "yup"
import {requiredMessage} from "../../../../../models/error/ModelErrorValidation";
import {ObjectSchema} from "yup";

export interface CaptchaModelData {
    key: string;
    image_url: string;
    audio_url: string;
}

// @ts-ignore
export const captchaModelDataSchema: ObjectSchema<any> = yup.object( {
    key: yup.string().required(requiredMessage),
    image_url: yup.string().required(requiredMessage),
    audio_url: yup.string().required(requiredMessage)
})