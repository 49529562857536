import {ObjectSchema} from "yup";
import {GetPagesData, GetPagesItemData} from "../page";

/**
 * Description - Method to validate item model from page data model
 * @param schemaValidation
 * @param data
 */
export const validatePagesItem = (schemaValidation: ObjectSchema<any>, data: GetPagesData):Promise<void> => {
    return new Promise((resolve, reject) => {
        const validations: Array<Promise<void>> = data.items.map(item => {
            return new Promise((resolveValidation, rejectValidation) =>  {
                schemaValidation.validate(item, {abortEarly: false}).then(() => {
                    resolveValidation()
                }).catch(errors => {
                    const errorsMessage: {[key: string]: string} = {}
                    errors.inner.forEach((e: {path: string, message: string}) => {
                        errorsMessage[e.path] = e.message
                    })
                    rejectValidation(errorsMessage)
                })
            })
        })
        return Promise.all(validations).then(() => resolve()).catch((errors) => reject({
            type: "validation",
            message: {
                title: "data validation",
                content: {...errors}
            }
        }))
    })
}

export const mapErrorValidationMessage = (errors: any): {[key: string]: string} => {
    const errorsMessage: {[key: string]: string} = {}
    errors.inner.forEach((e: {path: string, message: string}) => {
        errorsMessage[e.path] = e.message
    })
    return errorsMessage
}

export const validatePageItem = (schemaValidation: ObjectSchema<any>, data: GetPagesItemData): Promise<void> => {
    return new Promise((resolve, reject) => {
        schemaValidation.validate(data, {abortEarly: false}).then(() => {
            resolve()
        }).catch(errors => {
            reject({
                type: "validation",
                message: {
                    title: "data validation",
                    content: mapErrorValidationMessage(errors)
                }
            })
        })
    })
}