import React, {ReactElement, ReactNode, useCallback, useEffect, useRef, useState} from "react";

export interface ScrollInfiniteProps {
    onNext: () => void
    margin?: number
    threshold?: number,
    children: ReactNode,
    enableFromParent: boolean
    onTimeUsed?: boolean
}

/**
 * Description - Component to provide children and launch process as callback when the bottom of children component is view in screen
 * @param children
 * @param onNext
 * @param margin
 * @param threshold
 * @param enableFromParent
 * @param onTimeUsed
 * @constructor
 */
export function ScrollInfinite({children, onNext, margin, threshold, enableFromParent, onTimeUsed}: ScrollInfiniteProps): ReactElement<ScrollInfiniteProps> {
    const refLoader = useRef(null)

    // eslint-disable-next-line
    const [_enable, setEnable] = useState<boolean>(true)

    const observer = useRef<IntersectionObserver | null>(null)
    const handleObserver = useCallback((entries: any) => {
        setEnable(current => {
            const target = entries[0];
            if (target.isIntersecting && enableFromParent && current) {
                onNext()
                if (onTimeUsed) {
                    return false
                }
            }
            return current
        })

    }, [onNext, enableFromParent, onTimeUsed]);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: `${margin ? margin.toString() : "20"}px`,
            threshold: threshold ? threshold : 0
        };
        observer.current?.disconnect()
        observer.current = new IntersectionObserver(handleObserver, option);
        if (refLoader.current) observer.current.observe(refLoader.current);
    }, [handleObserver, threshold, margin]);

    return <React.Fragment>
        {children}
        <div ref={refLoader}/>
    </React.Fragment>

}