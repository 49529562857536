import {PageDispatchingModel} from "../../page/page-dispatching/models/PageDispatchingModel";
import {convertFooterDataToFooterView, FOOTER_WAGTAIL_MODEL_NAME} from "../FooterData";
import {FooterModelData, footerModelDataSchema} from "../models/FooterModelData";
import {FooterModelView} from "../models/FooterModelView";
import LandingPage from "../../page/page-model/landingPage/LandingPage";

export const footerDispatcherForPreview: PageDispatchingModel<FooterModelData, FooterModelView> = {
    wagtail_model_name: FOOTER_WAGTAIL_MODEL_NAME,
    serializer: convertFooterDataToFooterView,
    component: () => <LandingPage/>,
    schemaValidation: footerModelDataSchema
}