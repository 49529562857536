import {ImageAttribute} from "../../../models/general_model";
import React, {ReactElement, ReactNode} from "react";
import {Stack} from "react-bootstrap";
import RichText from "../RichText/RichText";
import PanelExpandable from "../collapse/panel-expandable/PanelExpandable";

export interface DefaultCardProps {
    id: string;
    header: ReactNode | ReactElement | null | string;
    content?: string;
    contentExpandable?: boolean;
    image?: ImageAttribute;
    customSectionComponent?: ReactNode | ReactElement | null;
    buttonActionComponent?: ReactNode | ReactElement | null;
    expandable?: boolean
    titleNotExpand?: boolean
}

/**
 * Description - UI Component to render card
 * @param id
 * @param header
 * @param content
 * @param image
 * @param customSectionComponent
 * @param buttonActionComponent
 * @param expandable
 * @param titleNotExpand
 * @constructor
 */
const DefaultCard: React.FC<DefaultCardProps> = ({id, header, content, image, customSectionComponent, buttonActionComponent, expandable, titleNotExpand}) => {

    return <Stack className="default-card stack-responsive" direction="horizontal">
        {image && <div
          style={{
              backgroundImage: `url(${image?.imageUrl})`,
          }}
          className="card-image xs-hidden"
        />}
        <div
            className={`card-content${!image ? " card-content-without-image" : ""}`}
        >
            <PanelExpandable
                childrenNotExpand={titleNotExpand}
                desktopNotExpandable={!expandable}
                header={typeof header === "string" ? <h5 >{header}</h5> : header}
                body={<React.Fragment>
                    {content !== undefined && <RichText content={content}/>}
                    {customSectionComponent}
                    {buttonActionComponent !== undefined && <div className="card-button-action-container">{buttonActionComponent}</div>}
                </React.Fragment>} id={id}/>
        </div>
    </Stack>
}

export default DefaultCard