import * as yup from "yup"
import {requiredMessage} from "../../../models/error/ModelErrorValidation";

export interface ActorModelData {
    id: number;
    name: string;
    acronym: string;
    content: string;
    logo:  number;
    url: string;
}

// @ts-ignore
export const actorDataSchema = yup.object({
    id: yup.string().required(requiredMessage),
    name: yup.string().required(requiredMessage),
    acronym: yup.string().nullable(),
    content: yup.string().required(requiredMessage),
    logo: yup.number().required(requiredMessage),
    url: yup.string().required(requiredMessage)
})

