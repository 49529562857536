import {RegistrationFormData} from "../../../../components/registration/models/RegistrationModelData";
import apiWagtailSubscribeClient from "../ApiWagtailSubscribeClient";

export const POST_SUBSCRIBE_URL = "/subscriptions/"

/**
 * Description - Process to make subscription to custom registration rest api
 * @param registration
 */
export const onSubscribe = (registration: RegistrationFormData) => new Promise((resolve, reject) => {
    apiWagtailSubscribeClient.post(POST_SUBSCRIBE_URL,  registration ).then(response => {
        resolve(response)
    }).catch(error => reject(error))
})