import {Container, Navbar} from "react-bootstrap";
import React from "react";
import MainMenu from "./main-menu/MainMenu";
import {useGetHeader} from "../header/HeaderData";
import ComponentSwitch from "../ui/component-switch/ComponentSwitch";
import MainMenuLoading from "./main-menu/MainMenuLoading";
import {useSwitch} from "../ui/collapse/panel-expandable/PanelExpandable";


export const MAIN_MENU_HTML_COMPONENT_ID = "main-menu"
/**
 * Description - Main navigation component
 *  - render header logo
 *  - render navigation main menu
 *  - manage nav bar toggle
 * @constructor
 */
const Navigation: React.FC = () => {

    const {onClose, open, onSwitch} = useSwitch()

    // in navbar we can use collapseOnSelect to create a same comportment but we have to implement Nav item with href property, in ons case they create some bug
        return (
        <div className="Navigation w-100">
            <Navbar onToggle={onSwitch} onSelect={onClose} expanded={open} expand="lg">
                <Container fluid>
                    <Navbar.Brand>
                        <HeaderLogo/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={MAIN_MENU_HTML_COMPONENT_ID}/>
                    <MainMenu/>
                </Container>

            </Navbar>
        </div>
    )
}

export default  Navigation

const HeaderLogo: React.FC = () => {
    const {data, isLoading, error} = useGetHeader()

    return <ComponentSwitch error={error} loading={isLoading} loadingComponent={<MainMenuLoading/>}>
        {data !== undefined && !isLoading && <div className="header-logo" style={{backgroundImage: `url(${data.logo.imageUrl})`}}></div>}
    </ComponentSwitch>
}