import {ApiObjectData} from "../../../../../data-access/api-call/wagtail-rest-page-api/models/ApiCallModel";
import {GetPagesItemData} from "../../../../../data-access/api-call/wagtail-rest-page-api/page";
import * as yup from 'yup'
import {requiredMessage} from "../../../../../models/error/ModelErrorValidation";
import {ObjectSchema} from "yup";
import {
    LandingPageGalleryModelData,
    landingPageGalleryModelDataSchema
} from "../ui/landing-page-gallery/models/LandingPageGalleryModelData";
import {
    LandingPageCardModelData, landingPageCardModelDataSchema
} from "../ui/landing-page-card/models/LandingPageCardModelData";
import {actorDataSchema, ActorModelData} from "../../../../actor/models/ActorModelData";

export interface LandingPageModelData extends GetPagesItemData {
    title: string;
    content: string;
    gallery_image: Array<LandingPageGalleryModelData>;
    card: Array<ApiObjectData<LandingPageCardModelData, "card">>
    landing_page_actor: Array<{actor: ActorModelData}>
}

// @ts-ignore
export const landingPageModelDataSchema: ObjectSchema<any> = yup.object( {
    title: yup.string().required(requiredMessage),
    content: yup.string().required(requiredMessage),
    gallery_image: yup.array(landingPageGalleryModelDataSchema).required(requiredMessage).nullable(),
    card: yup.array(landingPageCardModelDataSchema).required(requiredMessage).nullable(),
    landing_page_actor: yup.array(yup.object({
        actor: actorDataSchema
    }).required()).required(requiredMessage).nullable()
})