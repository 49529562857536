import {useApiQuery} from "../../data-access/data-caching";
import {useContext, useMemo} from "react";
import ApiCallContext from "../../data-access/api-call/ApiCallContext";
import {ImageAttribute} from "../../models/general_model";
import {GetImageData} from "../../data-access/api-call/wagtail-rest-page-api/image/models/ImageModel";
import {convertApiImageDataToImageAttributs} from "../../data-access/api-call/wagtail-rest-page-api/utils";

export const IMAGE_QUERY_NAME = "get-image";
export const IMAGE_MODEL_NAME = "Image";


/**
 * Description - Service to fetch image data from wagtail rest api
 * @param id
 */
export const useGetImage = (id?: string) => {

    const {getImage} = useContext(ApiCallContext)
    const {data, isLoading, error} = useApiQuery<GetImageData>(IMAGE_QUERY_NAME, getImage, {
        variables: id,
        model: IMAGE_MODEL_NAME
    })

    const imageAttribute: ImageAttribute | undefined = useMemo(() => !isLoading && data ? convertApiImageDataToImageAttributs(data) : undefined, [data, isLoading])

    return {data: imageAttribute, isLoading, error}
}