import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Container, Form, Stack} from "react-bootstrap";
import {OnSearchFormData} from "../../NewsPage";
import ThemeMenu from "../../../../../theme/ui/theme-menu/ThemeMenu";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import Radios from "../../../../../ui/form/radios/Radios";
import {SelectOption} from "../../../../../ui/form/models";

export interface NewsSearchFormProps {
    onSearch: (variables: OnSearchFormData) => void;
}

export type NewsSearchModeType ="by-title" | "by-tag"

export const NEWS_FORM_SEARCH_MODE_RADIO_KEY = {
    byTitle: "page.newPage.searchForm.searchType.byTitle",
    byTag: "page.newPage.searchForm.searchType.byTagAndTheme"
}

export const NEWS_FORM_SEARCH_FIELD_LABEL = {
    title: "page.newPage.searchForm.searchField.title",
    tag: "page.newPage.searchForm.searchField.tag",
    category: "page.newPage.searchForm.searchField.theme"
}

export const NEWS_FORM_SEARCH_MODE_RADIO_POP_HOVER = {
    byTitle: "page.newPage.searchForm.searchFieldPopOver.title",
}

export const NEWS_FORM_SEARCH_SUBMIT_BUTTON_LABEL = "page.newPage.searchForm.searchButton"

export const NEWS_FORM_TEST_ID = {
    form: "news-page-search-form"
}

/**
 * Description - Service to manage search mode in news search form
 */
export const useSearchMode = () => {

    const {t} = useTranslation()

    const [searchMode, setSearchModel] = useState<NewsSearchModeType>("by-title")

    const handleChange  = (value: NewsSearchModeType) => {
        setSearchModel(value)
    }

    const options: Array<SelectOption> = useMemo(() => {
        return [
            {
                label: t(NEWS_FORM_SEARCH_MODE_RADIO_KEY.byTitle),
                value: "by-title",
                tooltip: t(NEWS_FORM_SEARCH_MODE_RADIO_POP_HOVER.byTitle)

            },
            {
                label: t(NEWS_FORM_SEARCH_MODE_RADIO_KEY.byTag),
                value: "by-tag"
            }
        ]
    },[t])

    return {searchMode, onChangeSearchMode: handleChange, options}
}

export const defaultNewsSearchFormValues: OnSearchFormData = {
    titleQuery: "",
    tag: "",
    theme: ""
}

/**
 * Description - UI Component form to search news
 * @param onSearch
 * @constructor
 */
const NewsSearchForm: React.FC<NewsSearchFormProps> = ({onSearch}) => {

    const {searchMode, onChangeSearchMode, options} = useSearchMode()

    const {t} = useTranslation()

    const {register, handleSubmit, setValue} = useForm<OnSearchFormData>({
        defaultValues: defaultNewsSearchFormValues
    })

    const onSubmit = useCallback((values: OnSearchFormData) => {
        onSearch(values)
    },[onSearch])

    // reset appropriate input value when search mode change
    useEffect(() => {
        switch (searchMode){
            case "by-title":
                setValue("theme", "")
                setValue("tag", "")
                break;
            case "by-tag":
                setValue("titleQuery", "")
                break;
        }
    },[searchMode, setValue])

    const showTitleSearchField = useMemo(() => searchMode === "by-title", [searchMode])
    const showTagAndCategorySearchField = useMemo(() => searchMode === "by-tag", [searchMode])

    return <div data-testid={NEWS_FORM_TEST_ID.form} className="news-search-form">
        <Container>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="search-type">
                    <Radios options={options} onChange={onChangeSearchMode} value={searchMode}/>
                </div>
                <Stack className="stack-responsive" gap={3}>
                    {showTitleSearchField && <Form.Control type="text" placeholder={t(NEWS_FORM_SEARCH_FIELD_LABEL.title)} {...register("titleQuery")} />}
                    {showTagAndCategorySearchField && <React.Fragment>
                      <Form.Control type="text" placeholder={t(NEWS_FORM_SEARCH_FIELD_LABEL.tag)} {...register("tag")} />
                      <ThemeMenu placeholder={t(NEWS_FORM_SEARCH_FIELD_LABEL.category)} register={{...register("theme")}}/>
                    </React.Fragment>
                    }
                    <div className="vr xs-hidden" />
                    <Button variant="primary" type="submit" >{t(NEWS_FORM_SEARCH_SUBMIT_BUTTON_LABEL)}</Button>
                </Stack>
            </Form>
        </Container>
    </div>
}

export default NewsSearchForm