import {Spinner, Navbar} from "react-bootstrap";
import React from "react";
import {MAIN_MENU_HTML_COMPONENT_ID} from "../Navigation";

/**
 * Description - View component to display main menu in loading state
 * @constructor
 */
const MainMenuLoading: React.FC = () => {
    return <Navbar.Collapse id={MAIN_MENU_HTML_COMPONENT_ID} className="MainMenuLoading">
        <Spinner animation="border"/>
    </Navbar.Collapse>
}

export default MainMenuLoading