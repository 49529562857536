import React from "react";
import {ImageAttribute} from "../../models/general_model";
import {Image} from "react-bootstrap";
import {useGetImage} from "./ImageApplicationData";
import ErrorBoundaries from "../error-bounderies/ErrorBoundaries";

export interface ImageProps {
    imageId: string
    width?: number;
    height?: number;
}

export interface ImageViewProps {
    data: ImageAttribute
    width?: number;
    height?: number;
}


/**
 * Description - View Component to display image from api
 * @param data
 * @param height
 * @param width
 * @constructor
 */
export const ImageView: React.FC<ImageViewProps> = ({data, height,width}) => {
  return <Image
      fluid={height || width ? undefined : true}
      height={height}
      width={width}
      src={data.imageUrl}
      alt={data.altText}
  />
}

/**
 * Description - Component to render image from api rest
 *  - implement service to get image from wagtail rest api
 *  - rendering in loading state
 *  - rendering image view component
 * @param imageId
 * @param width
 * @param height
 * @constructor
 */
const ImageApplication: React.FC<ImageProps> = ({imageId, width, height}) => {
    const {data, isLoading, error} = useGetImage(imageId)

    return <ErrorBoundaries error={error}>
        {
            !isLoading && data !== undefined
                ? <ImageView data={data} width={width} height={height}/>
                : <div>...Loading</div>
        }
    </ErrorBoundaries>
}

export default ImageApplication