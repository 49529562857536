import * as yup from "yup";
import {requiredMessage} from "../../../models/error/ModelErrorValidation";
import {ObjectSchema} from "yup";
import {GetPagesItemData} from "../../../data-access/api-call/wagtail-rest-page-api/page";

export interface GDPRModel extends GetPagesItemData{
    title: string;
    content: string;
}

// @ts-ignore
export const gDPRModelDataSchema: ObjectSchema<any> = yup.object({
    title: yup.string().required(requiredMessage),
    content: yup.string().required(requiredMessage)
})

export interface GDPRModelView {
    title: string;
    content: string
}