import React, {useCallback, useContext, useEffect, useState} from "react";
import {DefaultProviderModel} from "../utils/provider/DefaultProviderModel";
import {SiteContext} from "../data-access/api-call/wagtail-rest-page-api/site/GetSiteId";
import {useTranslationService} from "../translation/TranslationProvider";

export interface SeoMetaModel {
    title: string
    description: string
}

export interface SeoContextProps {
    meta: SeoMetaModel,
    setMeta: (newMEta: SeoMetaModel) => void
}

export const DEFAULT_SEO_TITLE = "Belgian North Sea"
export const DEFAULT_SEO_DESCRIPTION = "It is website to inform belgian north sea activities"

export const DEFAULT_SEO_META = {title: DEFAULT_SEO_TITLE, description: DEFAULT_SEO_DESCRIPTION}
export const SeoContext = React.createContext<SeoContextProps>({
    meta: DEFAULT_SEO_META,
    setMeta: () => {}
})

export const SeoContextProvider = SeoContext.Provider

export const SeoProvider: React.FC<DefaultProviderModel> = ({children}) => {
    const [meta, setMeta] = useState<SeoMetaModel>(DEFAULT_SEO_META)

    const {seoMeta: metaFromSite} = useContext(SiteContext)
    const {language} = useTranslationService()

    const onChangeMeta = useCallback((newMeta: SeoMetaModel | undefined) => {
        setMeta( {
            title: newMeta?.title ? newMeta.title : metaFromSite.title ? metaFromSite.title : DEFAULT_SEO_TITLE,
            description: newMeta?.description ? newMeta.description : metaFromSite.description ? metaFromSite.description : DEFAULT_SEO_DESCRIPTION
        })
    }, [metaFromSite])

    useEffect(() => {
        const descriptionSelector = document.querySelector('meta[name="description"]')
        if(descriptionSelector){
            descriptionSelector.setAttribute('content', meta.description)
        } else {
            console.error("description selector is not found")
        }
        const titleSelector = document.querySelector('title')
        if(titleSelector){
            titleSelector.innerHTML = meta.title
        } else {
            console.error("title selector is not found")
        }
    }, [meta]);

    useEffect(() => {
        const htmlSelector = document.querySelector('html')
        if(htmlSelector){
            htmlSelector.setAttribute('lang', language)
        } else {
            console.error("language selector is not found")
        }
    }, [language]);
    return <React.Fragment>
        <SeoContextProvider value={{meta, setMeta: onChangeMeta}}>
            {children}
        </SeoContextProvider>
    </React.Fragment>
}

export const useSeoService = () => {
    return useContext(SeoContext)
}