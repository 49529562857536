import {PageDispatchingModel} from "../../page/page-dispatching/models/PageDispatchingModel";
import {serviceModelDataPreviewSchema, ServicePageModelData} from "../models/ServiceModelData";
import {ServiceModelView} from "../models/ServiceModelView";
import {
    convertServiceModelDataToServiceModelViewForPreview,
    THEME_SERVICE_INDEX_PAGE_WAGTAIL_MODEL_NAME,
    THEME_SERVICE_WAGTAIL_MODEL_NAME
} from "../ServiceData";
import ServiceTemplatePreview from "../../preview/service-template-preview/ServiceTemplatePreview";

export const serviceDispatchingForPreview: PageDispatchingModel<ServicePageModelData, ServiceModelView> = {
    wagtail_model_name: THEME_SERVICE_WAGTAIL_MODEL_NAME,
    serializer: convertServiceModelDataToServiceModelViewForPreview,
    component: (view) => <ServiceTemplatePreview data={view}/>,
    schemaValidation: serviceModelDataPreviewSchema
}

const serviceForPreview: ServiceModelView = {
    title: "Mission North Sea",
    description: "Spending time at national parks can be an exciting adventure, but this wasn't the type of excitement she was hoping to experience. As she contemplated the situation she found herself in, she knew she'd gotten herself in a little more than she bargained for. It wasn't often that she found herself in a tree staring down at a pack of wolves that were looking to make her their next meal.",
    link: [],
    themes: [
        {
            theme_page_id: "2",
            name: "theme"
        },
        {
            theme_page_id: "4",
            name: "theme 2"
        }
    ]
}

export const serviceIndexPageDispatchingForPreview: PageDispatchingModel = {
    wagtail_model_name: THEME_SERVICE_INDEX_PAGE_WAGTAIL_MODEL_NAME,
    component: () => <ServiceTemplatePreview data={serviceForPreview}/>
}