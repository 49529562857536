import {ApiVariables} from "../api-call/wagtail-rest-page-api/models/ApiCallModel";
import {useQuery} from "react-query";
import {useCallback, useContext, useMemo} from "react";
import {SiteContext} from "../api-call/wagtail-rest-page-api/site/GetSiteId";
import {ErrorApplication, ErrorApplicationData} from "../../models/error/ErrorModel";
import {TranslationContext} from "../../translation/TranslationProvider";
import {GetPagesVariables} from "../api-call/wagtail-rest-page-api/page";
import {ObjectSchema} from "yup";


export interface UseApiQueryOptions {
    schemaValidation?: ObjectSchema<any>,
    variables?: GetPagesVariables | string,
    skip?: boolean
    model?: string
}

export interface UseApiQueryResult<TData=unknown> {data?: TData, isLoading: boolean, error?: ErrorApplicationData, refetch?: () => Promise<TData>}

/**
 * Description - Component to call and caching data from API
 *
 * Remark - It is an implementation of react query with api call models and manage api call with caching
 * [react-query Documentation](https://react-query-v3.tanstack.com/guides/queries)
 * @param queryKey
 * @param process
 * @param options
 */
export const useApiQuery = <TData=unknown>(queryKey: string, process: any , options?: UseApiQueryOptions):
    UseApiQueryResult<TData> => {

    const {siteId} = useContext(SiteContext)
    const {language} = useContext(TranslationContext)

    const {variables, schemaValidation, model} = useMemo(() => {
        const variables = options?.variables ? options?.variables : undefined
        const schemaValidation = options?.schemaValidation ? options?.schemaValidation : undefined
        const model = options?.model ? options?.model : undefined
        return {variables, schemaValidation, model}
    },[options])

    const finalVariables: ApiVariables  = useMemo(() => {
        if(typeof variables === "string" || typeof variables === "number"){
            return variables
        }
        if(variables && siteId && siteId !== -1){
            return {...variables, descendant_of: siteId, locale: language}
        }
        if(variables && (!siteId || siteId === -1)){
            return {...variables, locale: language}
        }
        if(variables){
            return variables
        }
        if(!variables && (siteId && siteId !== -1)){
            return {descendant_of: siteId, locale: language}
        }
        if(!variables && (!siteId || siteId === -1)){
            return {locale: language}
        }
        throw new ErrorApplication(
            "problem to inject data",
            "run"
        )
    },[variables, siteId, language])

    const query = useMemo(() => {
        const queryResult = [queryKey]
        if(finalVariables){
            // @ts-ignore
            queryResult.push(finalVariables)
        }
        return queryResult
    },[queryKey, finalVariables])

    const processCallback = useCallback(() => process(finalVariables, schemaValidation), [process, finalVariables, schemaValidation])

    const {data, isLoading, error,  refetch,} = useQuery<TData, ErrorApplicationData, TData  >(query, processCallback,{retry: false, enabled: options?.skip})

    const finalError = useMemo(() => {
        if(error) {
            return {
                ...error, model
            }
        }
        return  undefined
    }, [error, model])
    // @ts-ignore
    return {data, isLoading, error: finalError, refetch}
}