/**
 * Description - Method to convert landing page model data from data access to model view
 * @param data
 */
import {LandingPageModelData, landingPageModelDataSchema} from "./models/LandingPageModelData";
import {LandingPageModelView} from "./models/LandingPageModelView";
import {useContext, useEffect, useMemo} from "react";
import ApiCallContext from "../../../../data-access/api-call/ApiCallContext";
import {useApiQuery} from "../../../../data-access/data-caching";
import {GetPagesData} from "../../../../data-access/api-call/wagtail-rest-page-api/page";
import {ErrorApplicationData} from "../../../../models/error/ErrorModel";
import {
    convertLandingPageGalleryModelDataToLandingPageGalleryModelView
} from "./ui/landing-page-gallery/models/LandingPageGalleryModelData";
import {convertActorModelDataToActorModelView} from "../../../actor/ActorData";
import {
    convertApiObjectDataToValue
} from "../../../../data-access/api-call/wagtail-rest-page-api";
import {useGetNavigationService} from "../../../navigation/navigation-service/NavigationService";
import {ActorModelData} from "../../../actor/models/ActorModelData";
import {useSeoService} from "../../../../seo";

export const LANDING_PAGE_WAGTAIL_MODEL_NAME = "landing_page.LandingPage"
export const LANDING_PAGE_QUERY_NAME = "get_landing_page"
export const LANDING_PAGE_MODEL_NAME = "Landing page"

/**
 * Description - Method ton convert landing page data model to landing page view model
 * @param data
 */
export const convertLandingPageDataToLandingPageView = (data: LandingPageModelData): LandingPageModelView => {
    const {title, content, gallery_image, card, landing_page_actor} = data
    return {
        title,
        content,
        galleryImages: gallery_image.map(image => convertLandingPageGalleryModelDataToLandingPageGalleryModelView(image)),
        card: card.map(item => convertApiObjectDataToValue(item)),
        actor: landing_page_actor.map(({actor}: {actor: ActorModelData}) => convertActorModelDataToActorModelView(actor))
    }

}

/**
 * Description - Service to fetch landing page data from wagtail rest api
 */
export const useGetLandingPage = ():{data: LandingPageModelView| undefined, isLoading: boolean, error: ErrorApplicationData | undefined} => {
    const {setMeta} = useSeoService()
    const {getPages} = useContext(ApiCallContext);
    const {setPageSelected} = useGetNavigationService()
    const {
        data,
        isLoading,
        error
    } = useApiQuery<GetPagesData<LandingPageModelData>>(LANDING_PAGE_QUERY_NAME, getPages, {
        variables: {
            fields: "*",
            type: LANDING_PAGE_WAGTAIL_MODEL_NAME
        },
        schemaValidation: landingPageModelDataSchema,
        model: LANDING_PAGE_MODEL_NAME
    });

    // landing page is the model for a default page
    useEffect(() => {
        if(data && data.items.length){
            setPageSelected(data.items[0].id)
        }
    },[data, setPageSelected])

    useEffect(() => {
        if(data && data.items.length){
            const pageData = data.items[0]
            setMeta({title: pageData.meta.seo_title, description: pageData.meta.search_description})
        }
    },[data, setMeta])

    const headerView = useMemo(() => {
        return data && data.items.length && !isLoading ? convertLandingPageDataToLandingPageView(data.items[0]) : undefined
    }, [data, isLoading])

    return {data: headerView , isLoading, error}
}
