import {ApiObjectData} from "../../../../data-access/api-call/wagtail-rest-page-api/models/ApiCallModel";
import * as yup from "yup"
import {requiredMessage} from "../../../../models/error/ModelErrorValidation";
import {ObjectSchema} from "yup";
import {GetPagesItemData} from "../../../../data-access/api-call/wagtail-rest-page-api/page";

export interface SubMenuItemModelData {
    label: string,
    link: number
}

export interface SubMenuModelData {
    title: string,
    item: Array<SubMenuItemModelData>
}

export const subMenuItemModelDataSchema = yup.object({
    label: yup.string().required(requiredMessage),
    link: yup.number().required(requiredMessage)
})

export const subMenuModelDataSchema = yup.object({
    title: yup.string().required(requiredMessage),
    item: yup.array(subMenuItemModelDataSchema).required(requiredMessage)
})


// @ts-ignore
export const menuModelDataSchema: ObjectSchema<any> = yup.object({
    title: yup.string(),
    submenu: yup.array(yup.object({
        id: yup.string(),
        value: subMenuModelDataSchema,
        key: yup.string()
    }))
})

export interface MainMenuModelData extends GetPagesItemData{
    title: string
    submenu: Array<ApiObjectData<SubMenuModelData,"submenu">>
}
