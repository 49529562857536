import {DefaultProviderModel} from "../../../../utils/provider/DefaultProviderModel";
import React from "react";

export interface DefaultCollapseProps extends DefaultProviderModel{
    open: boolean;
    id: string;
}

/**
 * Description - Collapse Component
 * @param children
 * @param open
 * @param id
 * @constructor
 */
const DefaultCollapse: React.FC<DefaultCollapseProps> = ({children, open, id}) => {
    return  <div className={`collapse${open ? " show" : ""}`}>
        <div id={id}>
            {children}
        </div>
    </div>
}

export default DefaultCollapse;