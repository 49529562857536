import React, {useContext, useMemo} from "react";
import {TranslationContext} from "../../../translation/TranslationProvider";
import {Nav, Spinner} from "react-bootstrap";
import {usePreview} from "../../../preview/PreviewService";
import {useApiQuery} from "../../../data-access/data-caching";
import ApiCallContext from "../../../data-access/api-call/ApiCallContext";
import ComponentSwitch from "../../ui/component-switch/ComponentSwitch";
import OverlayToolTip from "../../ui/tooltip/OverlayToolTip";
import {useTranslation} from "react-i18next";

export const VERIFY_LANGUAGE_CONTENT_QUERY_KEY = "verify-language-content-query"

/**
 * Description - MainMenu to select application language
 * @constructor
 */
const LanguageMenu = () => {
    const {language, onChangeLanguage, languages} = useContext(TranslationContext)
    const {verifyTranslationContent} = useContext(ApiCallContext)
    const {data, isLoading, error} = useApiQuery<Array<string | undefined>>(VERIFY_LANGUAGE_CONTENT_QUERY_KEY, verifyTranslationContent)

    // @ts-ignore
    const languagesVerified: Array<{language: string, active: boolean}> = useMemo(() => {
        if(data?.length){
            // @ts-ignore
            return languages.map(item => ({language: item, active: data?.includes(item)}))
        }
        return []
    }, [data, languages])

    return <ComponentSwitch loading={isLoading} loadingComponent={<Spinner/>} error={error}>
        {// @ts-ignore
        <LanguageMenuView language={language} onChangeLanguage={onChangeLanguage} languages={languagesVerified}/>
        }
    </ComponentSwitch>
}

export interface LanguageMenuViewProps {
    language: string,
    onChangeLanguage: (value: string) => void,
    languages: Array<{language: string, active: boolean}>
}

/**
 * Description - UI Component to render language main-menu
 * @param languages
 * @param language
 * @param onChangeLanguage
 * @constructor
 */
export const LanguageMenuView: React.FC<LanguageMenuViewProps> = ({languages, language, onChangeLanguage}) => {
    const {t} = useTranslation()
    const {isPreview} = usePreview()
    return (
        <Nav className="me-auto" activeKey={language} >
            {languages.map(languageOption => (
                languageOption.active ? <Nav.Link
                    key={languageOption.language}
                    eventKey={languageOption.language}
                        // disable language in preview
                    onClick={!isPreview ? () => onChangeLanguage(languageOption.language) : undefined}
                    disabled={isPreview}
                >
                    {languageOption.language.toUpperCase()}
                </Nav.Link>
                : <OverlayToolTip key={languageOption.language} label={t("fedTopBar.languageUnderConstructionMessage")}>
                    <Nav.Link
                        style={{textDecoration: "line-through"}}
                        eventKey={languageOption.language}
                        disabled={true}
                    >
                        {languageOption.language.toUpperCase()}
                    </Nav.Link>
                </OverlayToolTip>

            ))}
        </Nav>
    );
}

export default LanguageMenu