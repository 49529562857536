import {getPages, getPage, getTranslationId, GetPagesData, GetPagesItemData, GetPagesVariables} from "./wagtail-rest-page-api/page";
import {getImage} from "./wagtail-rest-page-api/image/ImageProcess"
import {GetImageData} from "./wagtail-rest-page-api/image/models/ImageModel";
import {getNews, verifyTranslationContent} from "./wagtail-rest-page-api/page/PageProcess";
import {GetThemesData} from "./wagtail-rest-snippet-api/theme/models/ThemeModel";
import {getThemes} from "./wagtail-rest-snippet-api/theme/ThemeProcess"
import {getActor} from "./wagtail-rest-snippet-api/actor/ActorProcess";
import {GetActorData} from "./wagtail-rest-snippet-api/actor/models/ActorModel";
import {RegistrationFormData} from "../../components/registration/models/RegistrationModelData";
import {onSubscribe} from "./wagtail-rest-suscribe-api/suscribe/SubscribeProcess";
import {CaptchaModelData} from "./rest-captcha-api/captcha/models/CaptchaModelData";
import {getCaptcha} from "./rest-captcha-api/captcha/CaptchaProcess";
import {ObjectSchema} from "yup";

export interface ApiCallType {
    getPages: ((variables: GetPagesVariables, schemaValidation?: ObjectSchema<any>) => Promise<GetPagesData | undefined >) | ((variables: GetPagesVariables) => GetPagesData | undefined),
    getPage:(id: string) => Promise<GetPagesItemData| undefined>,
    getTranslationId: (pageId: string, locale: string) => Promise<string | undefined>,
    getImage: (id: string) => Promise<GetImageData | undefined>,
    getNews: ((variables: GetPagesVariables, schemaValidation?: ObjectSchema<any>) => Promise<GetPagesData | undefined >) | ((variables: GetPagesVariables) => GetPagesData | undefined),
    getThemes: () => Promise<GetThemesData | undefined > | (() => GetThemesData | undefined)
    getActor:(id: string) => Promise<GetActorData| undefined>,
    onSubscribe: (registration: RegistrationFormData) => Promise<any | undefined>,
    getCaptcha: () => Promise<CaptchaModelData | undefined>
    verifyTranslationContent: () => Promise<Array<string | undefined>>
}

/**
 * Description - Object with all process to api rest
 */
const ApiCall: ApiCallType = {
    getPages,
    getPage,
    getTranslationId,
    getImage,
    getNews,
    getThemes,
    getActor,
    onSubscribe,
    getCaptcha,
    verifyTranslationContent
}

export default ApiCall