import axios from "axios";
import {onFulfilled, onInterceptRequest, onRejected, serializeVariables} from "../utils";

if(!process.env.REACT_APP_API_CUSTOM_URL) {
    throw Error("API_CUSTOM_URL variable is missing")
}

/**
 * Description - Instance of axios with main configuration for custom wagtail snippet rest api calling
 */
const apiWagtailSnippetClient = axios.create({
    baseURL: process.env.REACT_APP_API_CUSTOM_URL,
    timeout: 5000,
    paramsSerializer: serializeVariables
})

apiWagtailSnippetClient.interceptors.request.use((config) => {
    onInterceptRequest(config)
    return config
})

// api wagtail client response interception to manage a different case
apiWagtailSnippetClient.interceptors.response.use(onFulfilled , onRejected)

export default apiWagtailSnippetClient;