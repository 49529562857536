import {LandingPageCardItemModelView} from "./LandingPageCardModelView";
import * as yup from "yup"
import {requiredMessage} from "../../../../../../../models/error/ModelErrorValidation";


export type LandingPageCardModelData = LandingPageCardItemModelView;

export const landingPageCardModelDataSchema = yup.object({
   type: yup.string().required(requiredMessage),
   value: yup.object({
       title: yup.string().required(requiredMessage),
       content: yup.string().required(requiredMessage)
   }).required(requiredMessage),
   id: yup.string().required(requiredMessage)
})