import {useTranslationService} from "../../translation/TranslationProvider";
import {useApiQuery} from "../../data-access/data-caching";
import {
    GetThemeItemData,
    GetThemesData
} from "../../data-access/api-call/wagtail-rest-snippet-api/theme/models/ThemeModel";
import {ThemeModel} from "./models/ThemeModel";
import {mapper} from "../../utils/mapper/mapper";
import {useContext, useMemo} from "react";
import ApiCallContext from "../../data-access/api-call/ApiCallContext";

export const THEME_SNIPPET_QUERY_NAME = "get_theme_snippet"
export const THEME_SNIPPET_MODEL_NAME = "Theme Snippet"

export const convertThemeDataToThemeView = (data: GetThemeItemData): ThemeModel => {
    return mapper(data, {
        "name": "name",
        "id": "id"
    })
}

/**
 * Description - Service to fetch and cache all themes (wagtail snippet) from api
 */
export const useGetThemes = () => {
    const {language} = useTranslationService()
    const {getThemes} = useContext(ApiCallContext)

    const {data, isLoading, error} = useApiQuery<GetThemesData>(THEME_SNIPPET_QUERY_NAME,getThemes, {
        model: THEME_SNIPPET_MODEL_NAME
    })

    const themes = useMemo(() => {
        return  data && data.results.length && !isLoading
            ? data.results
                // filter by language
                .filter(({locale}) => locale === language)
                // convert for a view
                .map(themeItem => convertThemeDataToThemeView(themeItem))
            : undefined
    },[data, isLoading, language])

    return {data: themes, isLoading, error}
}