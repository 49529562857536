import {GDPRModelView} from "../../gdpr/models/GDPRModel";
import React from "react";
import {Modal} from "react-bootstrap";
import {GDPRDialogView} from "../../gdpr/GDPRDialog";
import SpacingMenu from "../../ui/spacing/SpacingMenu";

export interface GDPRTemplatePreviewProps {
    data: GDPRModelView
}

/**
 * Description - UI Component to render GDPR dialog review
 * @param data
 * @constructor
 */
const GDPRTemplatePreview: React.FC<GDPRTemplatePreviewProps> = ({data}) => {
    return <SpacingMenu>
        <Modal show={true}>
            <GDPRDialogView open={true} onClose={() => {}} data={data}/>
        </Modal>
    </SpacingMenu>
}

export default GDPRTemplatePreview