import {PageDispatchingModel} from "../../../page-dispatching/models/PageDispatchingModel";
import {
    REGISTRATION_PAGE_WAGTAIL_MODEL_NAME
} from "../RegistrationPageData";
import {RegistrationPageModelData, registrationPageModelDataSchema} from "../models/RegistrationPageModelData";
import RegistrationPageView from "../RegistrationPageView";

export const defaultRegistrationPageDispatcher: PageDispatchingModel<RegistrationPageModelData, RegistrationPageModelData> = {
    wagtail_model_name: REGISTRATION_PAGE_WAGTAIL_MODEL_NAME,
    serializer: (data) => data,
    component: (view) => <RegistrationPageView data={view}/>,
    schemaValidation: registrationPageModelDataSchema
}
