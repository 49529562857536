import {ActorModelView} from "../../../../../actor/models/ActorItemModelView";
import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import RichText from "../../../../../ui/RichText/RichText";
import ImageApplication from "../../../../../image/ImageApplication";

export interface LandingPageActorsSectionProps {
    data: Array<ActorModelView>
}

/**
 * Description - View component to display actor information in landing page
 * @param data
 * @constructor
 */
const LandingPageActorsSection: React.FC<LandingPageActorsSectionProps> = ({data}) => {
    return <div className="LandingPageActorsSection">
        <Container>
            {data.map(({name, acronym, content, logo, url}, index) => (
                <React.Fragment key={name}>
                    <Row className="section">
                        <Col>
                            <h5>{acronym}</h5>
                            <h2 className={"actor-name"}>{name}</h2>
                            <div className="section-border-end" ></div>
                        </Col>
                    </Row>
                    <Row className="section">
                        <Col sm={12} md={{order: index % 2 ? 1 : 10, span: 8}}>
                            <RichText content={content}/>
                        </Col>
                        <Col className="image-container sm-hidden" xs={{order: index % 2 ? 10 : 1, span: 4}}>
                            <a href={url} target="_blank" rel="noreferrer" >
                                <ImageApplication imageId={logo}/>
                            </a>
                        </Col>
                    </Row>
                </React.Fragment>
            ))}
        </Container>
    </div>
}

export default LandingPageActorsSection;