import {ServiceModelView} from "../../../../service/models/ServiceModelView";
import React from "react";
import {useNavigate} from "react-router";
import {usePreview} from "../../../../../preview/PreviewService";
import DefaultCard from "../../../../ui/card/DefaultCard";
import TagChips from "../../../../ui/chips/tag-chips/TagChips";
import {isMobile} from "react-device-detect";

export interface ThemeServiceProps {
    data: ServiceModelView
}


/**
 * Description - View Component to display service information in theme page
 * @param themes
 * @param title
 * @param description
 * @param link
 * @constructor
 */
export const ThemeService: React.FC<ThemeServiceProps> = ({data: {themes, title, description, link}}) => {
    const navigate = useNavigate()
    const {isPreview} = usePreview()

    return <div className="theme-service">
        <DefaultCard
        expandable
        titleNotExpand
        id={title}
        key={title}
        header={<div>
            {link.length === 1 ? <div className="link-title"><a  href={link[0].url} target="_blank" rel="noreferrer"><h5>{title}</h5></a></div> : <h5>{title}</h5>}
            <div role="button">{themes.map(({theme_page_id, name}, index) => (
                <span
                    role="navigation"
                    key={theme_page_id}
                    // disable in preview mode
                    onClick={!isPreview ? (() => navigate(`/${theme_page_id}`)) : undefined}>
                        {// no clickable in mode preview
                        }
                    <TagChips clickable={!isPreview} first={index === 0} content={name}></TagChips>
                    </span>))}
            </div>
        </div>
    }
        content={description}
        buttonActionComponent={
            <div>
                {link.length > 1 && link.map(({label, url}) => (
                    <div key={url} className="link">
                        <i className="bi bi-link-45deg"></i>
                        <a className={isMobile ? "mobile-device" : ""} target="_blank" rel="noreferrer" href={url}>{label}</a></div>
                ))}
            </div>
        }
    />
    </div>
}