import {CopyrightView, FooterModelView, FounderView, PathLinkView, SocialMediaView} from "./models/FooterModelView";
import {useApiQuery} from "../../data-access/data-caching";
import {GetPagesData} from "../../data-access/api-call/wagtail-rest-page-api/page";
import {
    AcknowledgmentPersonData, CookiesPreferencesData,
    CopyrightData, CopyrightNoticeData,
    FooterModelData, footerModelDataSchema,
    FounderData, PrivacyAndTermsData,
    SocialMediaData
} from "./models/FooterModelData";
import {convertApiObjectDataToValue} from "../../data-access/api-call/wagtail-rest-page-api";
import {useContext, useMemo} from "react";
import {Person} from "../../models/general_model";
import ApiCallContext from "../../data-access/api-call/ApiCallContext";
import {ErrorApplicationData} from "../../models/error/ErrorModel";

export const FOOTER_WAGTAIL_MODEL_NAME = "footer.FooterPage"
export const FOOTER_QUERY_NAME = "get_footer"
export const FOOTER_MODEL_NAME = "Footer"

/**
 * Description - Method to convert footer models data from data access to models view
 * @param data
 */
export const convertFooterDataToFooterView = (data: FooterModelData): FooterModelView => {
    const {project_description, project_title, acknowledgment, social_media, founder, copyright, privacy_and_terms, cookie_preference} = data

    return {
        project: {
            title: project_title,
            description: project_description,
            acknowledgement: acknowledgment
                ? acknowledgment.map((person) => {
                    return {...convertApiObjectDataToValue<AcknowledgmentPersonData, "person", Person>(
                            person, {
                                "firstname": "first_name" ,
                                "lastname": "last_name",
                                "email": {
                                    key: "email",
                                    optional: true
                                }
                            }
                        ), role: "developer"}
                })
                : undefined
        },
        socialMedia: social_media
            ? social_media.map((social_media_item) => {
                return {...convertApiObjectDataToValue<SocialMediaData, "social_media", SocialMediaView>(social_media_item)}
            })
            : undefined,
        founder: founder
            ?  founder.map(foundation => {
                return {...convertApiObjectDataToValue<FounderData, "founder", FounderView>(foundation)}
            })
            : undefined,
        copyright: copyright && copyright.length
            ? {...convertApiObjectDataToValue<CopyrightData, "copyright", CopyrightView>(
                    // wagtail give a list by default, we have to get first item
                    copyright[0], {
                        "dateBegin": "date_begin",
                        "notice": {
                            key: "notice",
                            optional: true,
                            serializer: (value: any) => {
                                return value.map((noticeValue: CopyrightNoticeData) => noticeValue.notice)
                            }
                        }
                    })
            }
            : undefined,
        privacyAndTerms: privacy_and_terms && privacy_and_terms.length
            ? convertApiObjectDataToValue<PrivacyAndTermsData, "privacy_and_terms",PathLinkView>(
                // wagtail give a list by default, we have to get first item
                privacy_and_terms[0]
            )
            : undefined
        ,
        cookiePreferences: cookie_preference && cookie_preference.length
            ? convertApiObjectDataToValue<CookiesPreferencesData, "cookie_preference", PathLinkView>(
                // wagtail give a list by default, we have to get first item
                cookie_preference[0]
            )
            : undefined
    }

}


/**
 * Description - Service to fetch footer data from wagtail rest api
 */
export const useGetFooter = ():{data: FooterModelView | undefined, isLoading: boolean, error?: ErrorApplicationData} => {
    const {getPages} = useContext(ApiCallContext)
    const {data, isLoading, error} = useApiQuery<GetPagesData<FooterModelData>>(FOOTER_QUERY_NAME, getPages, {
        variables: {
            fields: "*",
            type: FOOTER_WAGTAIL_MODEL_NAME,
        },
        schemaValidation: footerModelDataSchema,
        model: FOOTER_MODEL_NAME
    })

    const footerView = useMemo(() => {
        return data && data.items.length && !isLoading ? convertFooterDataToFooterView(data.items[0]) : undefined
    }, [data, isLoading])

    return {data: footerView , isLoading, error}
}
