import React from "react";

export interface DateInfoProps {
    date: string;
}

/**
 * Description - UI Component to render date information
 * @param date
 * @constructor
 */
const DateInfo: React.FC<DateInfoProps> = ({date}) => {
    return (
        <span className="date-info">
            <i className="bi bi-calendar2"></i>
            <p>{date}</p>
        </span>
    )
}

export default DateInfo;