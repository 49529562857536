import qs from "qs";
import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorApplicationData} from "../../models/error/ErrorModel";
import {convertRequestConfigToLogRequest, LogResponse} from "./api-log";

export const isDebug = () => process.env.REACT_APP_DEBUG_MODE === "true"

/**
 * Description - Method to serialize variables to request params
 * @param variables
 */
export const serializeVariables = <TVariable = unknown>(variables: TVariable): string => {
    return qs.stringify(variables, {arrayFormat: "comma", encode: true, encoder: ((str: string, _defaultEncoder, _charset, key,) => {
        if(key === "value"){
            return encodeURIComponent(str)
        }
        return str
    })})
}

/**
 * Description - Method to intercept request and log in debug mode
 *
 * @param config
 */
export const onInterceptRequest = (config: AxiosRequestConfig) => {
    if(isDebug()){
        console.info("%cRequest", "color: blue;")
        console.info(convertRequestConfigToLogRequest(config))
    }
}
/**
 * Description - Method to manage response in api client response interceptor
 * @param response
 */
export const onFulfilled = (response: AxiosResponse) => {
    let errorApplication: ErrorApplicationData | undefined;
    if (isDebug()) {
        const logResponse: LogResponse = {
            urlComplete: convertRequestConfigToLogRequest(response.config).urlComplete,
            data: response.data,
            headers: response.headers
        }
        console.info("%cResponse", "color: green;")
        console.info(logResponse)
    }
    if (response.status === 500) {
        errorApplication = {
            type: "api-problem",
            message: "Internal problem from api"
        }
    }

    if (errorApplication) {
        return Promise.reject(errorApplication)
    }
    return response
}
/**
 * Description - Method to manage error in api client response interceptor
 * @param error
 */
export const onRejected = (error: AxiosError) => {
    let errorApplication: ErrorApplicationData | undefined;
    if (isDebug()) {
        console.info("%cErrorResponse", "color: red")
        console.info({
            urlComplete: error.config ? convertRequestConfigToLogRequest(error.config).urlComplete : "",
            ...error
        })
    }
    if (error.code === "ERR_BAD_REQUEST") {
        errorApplication = {
            type: "api-bad-request",
            message: "It is bad request"
        }
    } else {
        errorApplication = {
            type: "api-no-access",
            message: "Problem to call api"
        }
    }
    return Promise.reject(errorApplication)
}