import React from "react";
import {useTranslation} from "react-i18next";
import MessagePanel from "../../../../../../ui/panel/MessagePanel";
import {labelKeys} from "../RegistrationForm";

/**
 * Description - View component to display message in success case
 * @constructor
 */
export const RegistrationFormSuccessPanel: React.FC = () => {

    const {t} = useTranslation()
    return <MessagePanel message={t(labelKeys.messageSuccess)}
                         link={{url: "/", label: t(labelKeys.buttonNavigationSuccess)}} variant="success"/>
}

/**
 * Description - View component to display message in error case
 * @constructor
 */
export const RegistrationFormCriticalError: React.FC = () => {
    const {t} = useTranslation()

    return <MessagePanel message={t(labelKeys.criticalError)}
                         link={{url: "/", label: t(labelKeys.buttonNavigationSuccess)}} variant="danger"/>
}